/* eslint-disable react/style-prop-object */
import React, { Component } from "react";

import "./EULA.scss";
import { LanguageSelector } from "../../components/UIComponents/LanguageSelector";
import i18n from "../../i18n";
import { AVATAR_BASE, contentsData } from "../../config";
require('dotenv').config();

export default class EULA extends Component {
    state = {
        eulaTextEn: '',
        eulaTextFr: ''
    };

    componentDidMount() {
        this.fetchEulaText();
    }

    fetchEulaText = async () => {
        // get from CDN link
        const eulaUrl = `${contentsData.CDN_URL}/${contentsData.EULA_PATH}`;
        const eulaTexts = await fetch(eulaUrl).then(response => response.json());
        this.setState({ eulaTextEn: eulaTexts.en, eulaTextFr: eulaTexts.fr });
    }

  render() {
    return (
      <div className="EULA">
        <LanguageSelector onChange={i18n.changeLanguage} value={i18n.language} />

        <div dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? this.state.eulaTextEn : this.state.eulaTextFr }}>
        </div>
      </div>
    );
  }
}
