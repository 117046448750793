import React from 'react';
import Checkbox from "rc-checkbox";

const CheckboxComponent = ({ disabled, defaultChecked, className, value, checked, ...props}) => {
  const classNames = `escCheckboxContainer${disabled ? ' disabled' : ''}${checked ? ' checked' : ''} ${className ?? ''}`; 
  return (
   <label className={classNames}>
     <Checkbox
       className="escCheckbox"
       defaultChecked={defaultChecked}
       onChange={(e) => props.onChange(e.target.checked)}
       disabled={disabled}
       value={value}
       checked={checked}
     />
     <p>{props.label || ""}</p>
   </label>
  )
};

export default CheckboxComponent;
