import React from 'react';
import { Button } from 'reactstrap';
import Controls from './Controls';
import { ReactComponent as SSIncoming } from '../../../assets/icons/direct-screen-share-incoming.svg';
import { ReactComponent as VIIncoming } from '../../../assets/icons/video-incoming.svg';
import { ReactComponent as VOIncoming } from '../../../assets/icons/voice-incoming.svg';
import UserAvatar from '../../UIComponents/UserAvatar';
import { CALL_PARTICIPANT_RATIO, callStatusesForParticipant, constantMessages, routes } from '../../../config';
import { ToastrService } from '../../../services';
import Participant from './Participant';
import { getIsShared } from "../../../utils/function-utils";
import { calculateRemoteParticipantSize, getAmountOfRowsAndParticipantsOnTheSameRow } from "../../../utils/call-utils";
import history from "../../../helpers/history";
import { isMobile, isSafari, isFirefox } from "../../../helpers/function";
import CallDuration from "./CallDuration";
import { withTranslation } from 'react-i18next';

class CallRoom extends React.Component {
  state = {
    isDisconnectEnabled: true
  };

  stopScreenShareRef = React.createRef(null);
  mediaContainer = React.createRef(null);

  toggleScreenShare = async () => {
    const { acceptedCall: { twId, participants }, localTracks, me, t } = this.props;

    const { clientWidth = 1080, clientHeight = 740 } = this.mediaContainer?.current || {};

    if (participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.id !== me.id && participant?.tracks?.find(track => track?.trackName === "screen"))) {
      ToastrService.warning(t('chat.err_one_user_share'));
      return
    }

    return getIsShared(localTracks) ?
      this.props.endShareScreen(twId) :
      this.props.startShareScreen(twId, isSafari ? {} : {
        height: clientHeight,
        width: clientWidth
      });
  };

  localErrorHandler = error => {
    const message = error.message || constantMessages.defaultErrorMessage;
    ToastrService.error(message)
  };

  onAddParticipant = () => {
    this.props.onAddParticipant(this.props.acceptedCall)
  };

  renderParticipantsTracks(participants, callParticipantsData) {
    return Array.from(participants)?.map((participant) => {
      const callParticipantData = callParticipantsData?.find(callParticipant => callParticipant.id === participant[1].identity);
      return (
        <Participant
          isSmall
          key={participant[1]?.identity}
          participant={participant[1]}
          callParticipantData={callParticipantData}
        />
      )
    })
  }

  renderRemoteParticipants(participants, participantWithScreenSharing, callParticipantsData, isSomeoneSharingScreen) {
    const { callParticipantContacts, forceVoiceToggle, localTracks, acceptedCall } = this.props;
    const isSharedByCurrentUser = getIsShared(localTracks);

    const remoteParticipants = Array.from(participants)?.filter(participant =>
      !!participant &&
      participant[1]?.state === 'connected' &&
      !!callParticipantContacts.find(user => user.id === participant[1]?.identity
      ));

    const {
      amountOfRows,
      amountOfParticipantsOnTheSameRow
    } = getAmountOfRowsAndParticipantsOnTheSameRow(remoteParticipants)

    const element = document.querySelector('.remote-media')
    // 10 - .participant-wrap top and bottom padding
    // 24 - height of participants name label
    const totalHeight = element?.offsetHeight - amountOfRows * (10 + 24);
    const totalWidth = element?.offsetWidth - amountOfParticipantsOnTheSameRow * 10;

    return remoteParticipants.map((participant, index) => {
      const isParticipantSharingScreen = participant[1].identity === participantWithScreenSharing?.identity;

      const callParticipantData = callParticipantsData?.find(callParticipant => callParticipant.id === participant[1].identity);

      let sizes = {}, height, width, maxWidth, maxHeight, minWidth = "unset", style = {};

      if (isParticipantSharingScreen) {
        width = element?.offsetWidth
        height = width / CALL_PARTICIPANT_RATIO
        maxHeight = (isMobile() ? element?.offsetHeight - 100 : element?.offsetHeight) * 0.7
        maxWidth = maxHeight * CALL_PARTICIPANT_RATIO
        style = {
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, 0)"
        }
      } else if (participantWithScreenSharing) {
        height = (isMobile() ? element?.offsetHeight - 100 : element?.offsetHeight) * 0.2;
        width = height * CALL_PARTICIPANT_RATIO
        style = {
          margin: "0 auto"
        }
      } else {
        sizes = {
          ...calculateRemoteParticipantSize(
            amountOfRows,
            amountOfParticipantsOnTheSameRow,
            remoteParticipants.length,
            index,
            totalWidth,
            totalHeight
          )
        };
        width = sizes.maxWidth
        height = sizes.maxHeight
        maxHeight = sizes.maxHeight
        maxWidth = sizes.maxWidth
      }

      const contact = callParticipantContacts.find(c => c.id === participant[1]?.identity);
      return (
        <div
          className='participant-wrap'
          style={{
            ...style,
          }}
          key={participant[1]?.identity}
        >
          <Participant
            sizes={{ height, width, maxHeight, maxWidth }}
            participant={participant[1]}
            callParticipantData={callParticipantData}
            isSomeoneSharingScreen={isSomeoneSharingScreen}
            forceVoiceToggle={(eventName) => isSharedByCurrentUser && forceVoiceToggle(acceptedCall.twId, false, eventName, callParticipantData.id)}
            isSharedByCurrentUser={isSharedByCurrentUser}
          />
          <div className='participant-name'>{contact.fullName}</div>
        </div>
      )
    });

  }

  openChatRoom = () => {
    history.push({
      pathname: routes.recent.path,
      state: { selectedChatRoomTWId: this.props.acceptedCall.twId }
    })
  }

  handleDisconnect = () => {
    const { endCall, endCallForAll, me, isDirectScreenShare, acceptedCall } = this.props;
    isDirectScreenShare && acceptedCall?.initializerId === me?.id ? endCallForAll(acceptedCall.twId) : endCall(acceptedCall.twId)
  }

  renderHeader() {
    const { acceptedCall, chatRoom, isSmall, endCallForAll, callName, isDirectScreenShare, isVideoCall, me, isOwner, isCallInitializer, t } = this.props;
    const { callRoom: { participants } } = acceptedCall;

    const userInCallParticipants = acceptedCall.participants?.find(participant => participant.id === me.id);

    const canEndCallForAll = isCallInitializer && participants.size > 1 && !isDirectScreenShare;

    const type = isDirectScreenShare ? t('chat.dir_screen_share') : isVideoCall ? t("chat.video_call") : t("chat.audio_call");

    return (
      <div className='call-header justify-content-between'>
        <div className='d-flex'>
          {isDirectScreenShare ?
            <SSIncoming className={`${isSmall ? 'clickable ' : ''} video-icon`}
              onClick={() => isSmall && this.openChatRoom()} /> :
            isVideoCall ?
              <VIIncoming className={`${isSmall ? 'clickable ' : ''} video-icon`}
                onClick={() => isSmall && this.openChatRoom()} /> :
              <VOIncoming className={`${isSmall ? 'clickable ' : ''} video-icon`}
                onClick={() => isSmall && this.openChatRoom()} />
          }
          <div className='call-info'>
            <div className='title'>{callName}</div>
            <CallDuration type={type} />
          </div>
        </div>
        <div>
          <Controls
            isAccepted
            isDirectScreenShare={isDirectScreenShare}
            isVideoCall={isVideoCall}
            onDisconnect={this.handleDisconnect}
            onEndCallForAll={() => endCallForAll(acceptedCall.twId)}
            isVideoEnabled={userInCallParticipants?.video}
            isOwner={isOwner}
            canEndCallForAll={canEndCallForAll}
            onAddParticipant={chatRoom?.type !== 'direct' && this.onAddParticipant}
            isSmall={isSmall}
          />
        </div>
      </div>
    )
  }

  renderParticipants(isSomeoneSharingScreen) {

    const { acceptedCall, isSmall, isDirectScreenShare, me, callParticipantContacts, startShareScreen, t } = this.props

    const { callRoom: { localParticipant, participants }, initializerId, participants: callParticipantsData } = acceptedCall;

    const otherParticipantWithScreenSharing = acceptedCall.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.id !== me.id && participant.screenShare);

    const remoteParticipantsContainerStyles = !!otherParticipantWithScreenSharing ? {
      overflow: "auto hidden",
      flexWrap: "nowrap",
      alignItems: "flex-end",
      justifyContent: "unset",
      marginBottom: isMobile() ? "90px" : 0
    } : {};

    if (isDirectScreenShare) {
      const directScreenShareInitializer = localParticipant.identity === initializerId ? localParticipant : participants.find(participant => participant && participant?.identity === initializerId);

      const isScreenShared = Array.from(directScreenShareInitializer?.tracks || {}).find(track => track.trackName === "screen" || (track[1] && track[1].trackName === "screen"));
      const element = document.querySelector('.call-body')
      // 60 - padding sum
      // 24 - participant name label height
      const totalHeight = element?.offsetHeight - 24 - 60
      const totalWidth = element?.offsetWidth - 60

      const currentRatio = totalWidth / totalHeight

      const style = {
        maxHeight: totalHeight,
        maxWidth: totalWidth,
        width: currentRatio > CALL_PARTICIPANT_RATIO ? totalHeight * CALL_PARTICIPANT_RATIO : totalWidth,
        height: currentRatio > CALL_PARTICIPANT_RATIO ? totalHeight : totalWidth / CALL_PARTICIPANT_RATIO,
      }


      if (directScreenShareInitializer && isScreenShared) {
        const contact = callParticipantContacts.find(c => c.id === directScreenShareInitializer.identity);
        return (
          <div className={`call-body directScreenShare${isSmall ? " isSmall" : ""}`}>
            <div className="participant-wrap m-auto">
              <Participant
                sizes={style}
                participant={directScreenShareInitializer}
                isDirectScreenShare
                isSharedByCurrentUser={localParticipant.identity === initializerId}
              />
              {!isSmall && <div className='participant-name'>{contact?.fullName}</div>}
            </div>
          </div>
        )
      }

      if (localParticipant.identity === initializerId) {
        return (
          <div className={`callStartingMessageContainer${isSmall ? " isSmall" : ""}`}>
            <div className='callStartingMessage'>
              <h3>{t('chat.share_your_screen')}</h3>
              {(isSafari || isFirefox) && <Button className="safariShareScreenButton" onClick={() => startShareScreen(acceptedCall.twId, {})}>{t('SHARE')}</Button>}
            </div>
          </div>
        )
      }

      return (
        <div className={`callStartingMessageContainer${isSmall ? " isSmall" : ""} short`}>
          <div className='callStartingMessage'>
            <h3>{t('pls_wait')}</h3>
            <p>{t('chat.wait_for_other')}</p>
          </div>
        </div>
      )
    } else {
      if (isSmall) {
        return this.renderParticipantsTracks(participants, callParticipantsData);
      } else {
        return (
          <div className='call-body'>
            <div className='media' ref={this.mediaContainer}>
              <div
                className={`remote-media position-relative${otherParticipantWithScreenSharing ? " align-items-end" : ""}`}>
                <div style={remoteParticipantsContainerStyles} className={`participants-wrap`}>
                  {this.renderRemoteParticipants(participants, otherParticipantWithScreenSharing, callParticipantsData, isSomeoneSharingScreen)}
                </div>
              </div>
              <div className='local-media'>
                {localParticipant && <Participant
                  participant={localParticipant}
                  isLocalParticipant
                />}
              </div>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    const { 
      acceptedCall, chatRoom, callParticipantContacts, isSmall, toggleFullScreen, fullScreenEnabled, toggleTrack,
      handleVoiceToggle, forceVoiceToggle, localTracks, isDirectScreenShare, me, licensing
    } = this.props

    const isShared = getIsShared(localTracks);
    if (!acceptedCall?.callRoom) {
      return null
    }
    const userInCallParticipants = acceptedCall.participants?.find(participant => participant.id === me.id);

    const otherParticipantWithScreenSharing = acceptedCall.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.id !== me.id && participant.screenShare);

    const areAllOtherParticipantsMutedDuringScreenShare = !acceptedCall.participants?.some(participant => participant.id !== me.id && participant.voiceDuringScreenShare);

    const localParticipant = acceptedCall?.callRoom?.localParticipant;
    let isVideoPublished = false;
    let isAudioPublished = false;

    if (localParticipant?.tracks?.values()) {
      const localParticipantPublications = Array.from(localParticipant.tracks.values());
      isVideoPublished = !!(localParticipantPublications.find(p => p.trackName === "screen" && p.kind === "video") || localParticipantPublications.find(p => p.kind === "video"))
      isAudioPublished = !!localParticipantPublications.find(p => p.kind === "audio")
    }


    return (
      <>
        {this.renderHeader()}
        <div className={`${isSmall ? 'mt-3 ' : ''}call-header justify-content-between`}>
          {!isSmall &&
            <div className='d-flex justify-content-center flex-grow-1'>
              {callParticipantContacts?.map((user = {}) => (<UserAvatar key={user.id} user={user} />))}
            </div>
          }
          <Controls
            isAccepted
            isDirectScreenShare={isDirectScreenShare}
            toggleLocalVideo={!licensing.video ? undefined : () => toggleTrack('video')}
            toggleLocalAudio={() => handleVoiceToggle(acceptedCall.twId)}
            forceVoiceToggle={(eventName) => forceVoiceToggle(acceptedCall.twId, true, eventName)}
            isVideoEnabled={isVideoPublished}
            isAudioEnabled={isAudioPublished && ((!!otherParticipantWithScreenSharing || isShared) ? userInCallParticipants?.voiceDuringScreenShare : userInCallParticipants?.voice)}
            screenSharing={this.toggleScreenShare}
            isOtherParticipantSharingTheScreen={!!otherParticipantWithScreenSharing}
            isScreenShared={isShared}
            areAllOtherParticipantsMutedDuringScreenShare={areAllOtherParticipantsMutedDuringScreenShare}
            fullScreenEnabled={fullScreenEnabled}
            toggleFullScreen={toggleFullScreen}
            isSmall={isSmall}
            isDirectChatRoom={chatRoom?.type === "direct"}
          />
        </div>
        {this.renderParticipants(isShared || !!otherParticipantWithScreenSharing)}
      </>
    )
  }
}

export default withTranslation('common')(CallRoom);
