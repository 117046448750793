import i18n from "../i18n";

export const AVATAR_BASE = process.env.REACT_APP_UPLOADS_BASE_PATH;
export const STATIC_BASE = process.env.REACT_APP_STATIC_BASE_PATH;

export const ENABLE_DRAG_CONTACTS = false;

// Possible Themes
export const appTheme = {
  LIGHT: 'light',
  DARK: 'dark'
};

// Possible User Roles
export const userRoles = {
  FACILITY_ADMIN: 'FacilityAdmin',
  REGULAR: 'Regular',
  ADMIN: 'Admin'
};

// Possible User Statuses
export const userStatuses = [
  {id: 1, value: "Available", class: "available"},
  {id: 2, value: "Away", class: "away"},
  {id: 3, value: "Do not disturb", class: "do-not-disturb"},
  {id: 4, value: "Offline", class: "offline"},
];

// All routes of the application
export const routes = {
  login: {
    path: '/login'
  },
  register: {
    path: '/register'
  },
  forgotPassword: {
    path: '/forgot-password'
  },
  resetPassword: {
    path: '/reset-password'
  },
  forceChangePassword: {
    path: '/set-password'
  },
  settings: {
    path: '/settings',
    users: [
      userRoles.REGULAR,
      userRoles.FACILITY_ADMIN,
      userRoles.ADMIN
    ]
  },
  emailConfirm: {
    path: '/email-confirm'
  },
  mfaPage: {
    path: '/mfa-auth'
  },
  mobileConfirm: {
    path: '/mobile-confirm'
  },
  admin: {
    path: '/admin',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  users: {
    path: '/admin/users',
    pageName: 'users',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  unverifiedUsers: {
    path: '/admin/unverified-users',
    pageName: 'unverified',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  user: {
    path: '/admin/users/:id',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  facilities: {
    path: '/admin/facilities',
    pageName: 'facilities',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  facility: {
    path: '/admin/facilities/:id',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  departments: {
    path: '/admin/departments',
    pageName: 'departments',
    users: [
      userRoles.ADMIN
    ]
  },
  department: {
    path: '/admin/departments/:id',
    users: [
      userRoles.ADMIN
    ]
  },
  roles: {
    path: '/admin/roles',
    pageName: 'roles',
    users: [
      userRoles.ADMIN
    ]
  },
  role: {
    path: '/admin/roles/:id',
    users: [
      userRoles.ADMIN
    ]
  },
  specialties: {
    path: '/admin/specialties',
    pageName: 'specialties',
    users: [
      userRoles.ADMIN
    ]
  },
  specialty: {
    path: '/admin/specialties/:id',
    users: [
      userRoles.ADMIN
    ]
  },
  suffixes: {
    path: '/admin/suffixes',
    pageName: 'suffixes',
    users: [
      userRoles.ADMIN
    ]
  },
  logos: {
    path: '/admin/logos',
    pageName: 'logos',
    users: [
      userRoles.ADMIN
    ]
  },
  about: {
    path: '/admin/about',
    pageName: 'about',
    users: [
      userRoles.ADMIN
    ]
  },
  eulaContent: {
    path: '/admin/eula-content',
    pageName: 'eula-content',
    users: [
      userRoles.ADMIN
    ]
  },
  suffix: {
    path: '/admin/suffixes/:id',
    users: [
      userRoles.ADMIN
    ]
  },
  logs: {
    path: '/admin/user-logs',
    pageName: 'user logs',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  bannerMessage: {
    path: '/admin/banner',
    pageName: 'banner-meesage',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  licensing: {
    path: '/admin/licensing',
    pageName: 'licensing',
    users: [
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  recent: {
    path: '/',
    users: [
      userRoles.REGULAR,
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  contacts: {
    path: '/contacts',
    users: [
      userRoles.REGULAR,
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  group: {
    path: '/group',
    users: [
      userRoles.REGULAR,
      userRoles.ADMIN,
      userRoles.FACILITY_ADMIN,
    ]
  },
  aboutUs: {
    path: '/about-us'
  },
  eula: {
    path: '/eula'
  },
  information: {
    path: '/information'
  },
  error: {
    path: '/404'
  },
};

// Constant messages
export const constantMessages = {
  registerUserSuccess: i18n.t("misc.user_registered_success"),
  defaultErrorMessage: i18n.t("misc.something_went_wrong"),
  chatRoomLongFriendlyNameErrorMessage: i18n.t("misc.group_name_error"),
  chatRoomNameExistErrorMessage: i18n.t("misc.group_name_exist"),
  jwtExpiredErrorMessage: i18n.t("misc.session_expired"),
};

// System messages
export const systemMessages = {
  CALL_INIT: "CALL_INIT",
  CALL_OUT_INIT: "CALL_OUT_INIT",
  CALL_DECLINED: "CALL_DECLINED",
  CALL_ABORTED: "CALL_ABORTED",
  CALL_ACCEPTED: "CALL_ACCEPTED",
  CALL_JOINED: "CALL_JOINED",
  CALL_LEFT: "CALL_LEFT",
  CALL_ENDED: "CALL_ENDED",
  CALL_MISSED: "CALL_MISSED",

  DIRECT_SCREEN_SHARE_OUT_INIT: "DIRECT_SCREEN_SHARE_OUT_INIT",
  DIRECT_SCREEN_SHARE_INIT: "DIRECT_SCREEN_SHARE_INIT",
  DIRECT_SCREEN_SHARE_ACCEPTED: "DIRECT_SCREEN_SHARE_ACCEPTED",
  DIRECT_SCREEN_SHARE_JOINED: "DIRECT_SCREEN_SHARE_JOINED",
  DIRECT_SCREEN_SHARE_DECLINED: "DIRECT_SCREEN_SHARE_DECLINED",
  DIRECT_SCREEN_SHARE_ABORTED: "DIRECT_SCREEN_SHARE_ABORTED",
  DIRECT_SCREEN_SHARE_LEFT: "DIRECT_SCREEN_SHARE_LEFT",
  DIRECT_SCREEN_SHARE_ENDED: "DIRECT_SCREEN_SHARE_ENDED",

  VIDEO_CALL_INIT: "VIDEO_CALL_INIT",
  VIDEO_OUT_CALL_INIT: "VIDEO_OUT_CALL_INIT",
  VIDEO_CALL_DECLINED: "VIDEO_CALL_DECLINED",
  VIDEO_CALL_ABORTED: "VIDEO_CALL_ABORTED",
  VIDEO_CALL_ACCEPTED: "VIDEO_CALL_ACCEPTED",
  VIDEO_CALL_JOINED: "VIDEO_CALL_JOINED",
  VIDEO_CALL_LEFT: "VIDEO_CALL_LEFT",
  VIDEO_CALL_ENDED: "VIDEO_CALL_ENDED",

  SCREEN_SHARING_STARTED: "SCREEN_SHARING_STARTED",
  SCREEN_SHARING_STOPPED: "SCREEN_SHARING_STOPPED",

  USER_ADDED: "USER_ADDED",
  USER_REMOVED: "USER_REMOVED",
  USER_JOINED: "USER_JOINED",
  USER_LEFT: "USER_LEFT"
};

export const errorCodes = {
  EMAIL_NOT_REGISTERED: 'EMAIL_NOT_REGISTERED',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  PHONE_NOT_VERIFIED: 'PHONE_NOT_VERIFIED',
  INACTIVE_USER: 'INACTIVE_USER',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  DELETED_USER: 'DELETED_USER',
  ERROR_GENERATING_TOKEN: 'ERROR_GENERATING_TOKEN'
};

export const CALL_PARTICIPANT_RATIO = 1.77777777778;

export const SocketMessageType = {
  // Client -> Server
  CHANGE_CHAT_STATUS: 'changeChatStatus',
  SEND_MESSAGE: 'SendMessage',
  READ_MESSAGE: 'ReadMessage',

  DIRECT_SCREEN_SHARE_INITIALIZED_CLIENT: 'DirectScreenShareInitializedClient',
  CALL_INITIALIZED_CLIENT: 'CallInitializedClient',
  CALL_ACCEPTED_CLIENT: 'CallAcceptedClient',
  CALL_DECLINED_CLIENT: 'CallDeclinedClient',
  CALL_STARTED_CLIENT: 'CallStartedClient',
  CALL_ENDED_CLIENT: 'CallEndedClient',
  CALL_END_FOR_ALL_CLIENT: 'CallEndedForAllClient',
  START_SCREEN_SHARE_CLIENT: 'StartScreenShareClient',
  END_SCREEN_SHARE_CLIENT: 'EndScreenShareClient',

  CALL_PARTICIPANT_MUTE_CLIENT: 'CallParticipantMuteClient',
  CALL_PARTICIPANT_UNMUTE_CLIENT: 'CallParticipantUnMuteClient',

  CALL_PARTICIPANT_MUTE_DURING_SCREEN_SHARE_CLIENT: 'CallParticipantMuteDuringScreenShareClient',
  CALL_PARTICIPANT_UNMUTE_DURING_SCREEN_SHARE_CLIENT: 'CallParticipantUnMuteDuringScreenShareClient',

  // Server -> Client
  CHAT_STATUS_CHANGED: 'chatStatusChanged',
  USER_DATA_UPDATED: 'UserDataUpdated',
  USER_IS_ONLINE: 'UserIsOnline',
  USER_IS_OFFLINE: 'UserIsOffline',
  FORCED_LOG_OUT: 'ForcedLogout',
  FORCED_DELETE: 'ForcedDelete',
  DUPLICATE_SESSION: 'DuplicateSession',
  CONVERSATION_CREATED: 'ConversationCreated',
  CONVERSATION_UPDATED: 'ConversationUpdated',
  CHAT_ROOM_DELETED: 'ChatRoomIsDeleted',
  CHAT_ROOM_UPDATED: 'ChatRoomIsUpdated',
  MarkImportantMessage: 'MarkImportantMessage',
  MessageReadNotification: 'MessageReadNotification',
  AcknowledgeMessage: 'AcknowledgeMessage',
  USER_PERMISSION_CHANGED_SERVER: 'UserPermissionChanged',
  LicensingSettingsUpdate: 'LicensingSettingsUpdate',

  DIRECT_SCREEN_SHARE_INITIALIZED_SERVER: 'DirectScreenShareInitializedServer',
  CALL_INITIALIZED_SERVER: 'CallInitializedServer',
  CALL_ACCEPTED_SERVER: 'CallAcceptedServer',
  CALL_DECLINED_SERVER: 'CallDeclinedServer',
  CALL_STARTED_SERVER: 'CallStartedServer',
  CALL_ENDED_SERVER: 'CallEndedServer',
  START_SCREEN_SHARE_SERVER: 'StartScreenShareServer',
  END_SCREEN_SHARE_SERVER: 'EndScreenShareServer',
  USER_LOG_UPDATED: 'UserLogUpdated',

  CALL_PARTICIPANT_MUTE_SERVER: 'CallParticipantMuteServer',
  CALL_PARTICIPANT_UNMUTE_SERVER: 'CallParticipantUnMuteServer',

  CALL_PARTICIPANT_MUTE_DURING_SCREEN_SHARE_SERVER: 'CallParticipantMuteDuringScreenShareServer',
  CALL_PARTICIPANT_UNMUTE_DURING_SCREEN_SHARE_SERVER: 'CallParticipantUnMuteDuringScreenShareServer',

  SYSTEM_MESSAGE_RECEIVED: "SystemMessageFromServer",

  REMOVE_MESSAGE: "RemoveMessage",
  NEW_USER_REGISTERED_SUCCESSFULLY: "NewUserRegisteredSuccessfully",

  PING: "Ping",
  PONG: "Pong",

  StartVoiceTranscription: 'start_voice_trancription',
  StopVoiceTranscription: 'stop_voice_trancription',
  VoiceTranscriptionStarted: 'voice_transcription_started',
  VoiceTranscriptionError: 'voice_transcription_error',
  SendTranscriptionBlobs: 'send_voice_blob',
  VoiceTranscriptionTextData: 'voice_transcription_text'
};

export const callStatuses = {
  INITIALIZED: 'initialized',
  STARTED: 'started',
  ENDED: 'ended',
};

export const callStatusesForParticipant = {
  RINGING: 'ringing',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  DROPPED: 'dropped',
};

export const appSounds = {
  CALL_SOUND: 'soundService/CALL_SOUND',
  MESSAGE_CALL_SOUND: 'soundService/MESSAGE_CALL_SOUND',
};

export const soundsPath = {
  MESSAGE_SOUND_PATH: './assets/audio/message.mp3',
  VIDEO_SOUND_PATH: './assets/audio/call.mp3',
  VOICE_SOUND_PATH: './assets/audio/call.mp3',
};

export const callRoomEventType = {
  VOICE: "voice",
  VIDEO: "video",
  SCREEN_SHARE: "screenshare"
};

export const countryCodesDefaults = new Map([
  ["+1", "United States"],
  ["+7", "Russia"],
  ["+47", "Norway"],
  ["+61", "Australia"],
  ["+64", "New Zealand"],
  ["+212", "Morocco"],
  ["+262", "Mayotte"],
  ["+590", "Saint Martin"],
  ["+599", "Curacao"],
]);

export const defaultCountryCodeAndName = '(+1) - United States';

export const contentsData = {
  CDN_URL: process.env.REACT_APP_CONTENTS_STORAGE_CDN_URL,
  ABOUT_PATH: process.env.REACT_APP_CONTENTS_ABOUT_PATH,
  INFORMATION_PATH: process.env.REACT_APP_CONTENTS_INFORMATION_PATH,
  EULA_PATH: process.env.REACT_APP_CONTENTS_EULA_PATH
};
  