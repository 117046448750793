import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CallComponent from '../../components/Call';
import {
  toggleTrack,
  declineCall,
  acceptCall,
  endCall,
  endCallForAll,
  startShareScreen,
  endShareScreen,
  handleVoiceToggle,
  forceVoiceToggle
} from "../../reducers/calls";
import {changeGroupMode, toggleGroupModal} from "../../reducers/chat";
import { openModal } from "../../reducers/group";

const mapStateToProps = state => {
  return {
    calls: state.calls.calls || [],
    localTracks: state.calls.localTracks || [],
    chatRooms: state.chatRooms.list,
    contacts: state.contacts.list,
    licensing: state.setting.licensing,

    selectedChatRoomTWId: state.chatRooms.selectedChatRoomTWId,
    me: state.auth.data
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  endCall,
  endCallForAll,
  declineCall,
  acceptCall,
  toggleTrack,
  startShareScreen,
  endShareScreen,
  changeGroupMode,
  toggleGroupModal,
  handleVoiceToggle,
  forceVoiceToggle,
  openGroupModal: openModal
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallComponent);
