import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import UsersComponent from '../../../components/Admin/Components/User/UsersComponent/UsersComponent';
import {
  FETCH_USERS_LIST_PREFIX,
  getUsersList,
  UPDATE_USER_PREFIX,
  updateUser,
  CHANGE_USER_STATUS_PREFIX,
  changeUserStatus,
  deleteUser,
  DELETE_USER_PREFIX,
  updateUserPermissions,
  resetUserMFA,
  updateAllUsersPermissions, changeUsersRole,
  bulkUsersCreate,
  BULK_USERS_CREATE_PREFIX,
  clearBulkUsersCreateResults
} from "../../../reducers/user";
import {createLoadingSelector} from "../../../selectors/loading";
import {createErrorMessageSelector} from "../../../selectors/error";
import { changeSetting, getSettings } from "../../../reducers/auth";
import { getSelfRegistrationStatus } from "../../../reducers/setting";

const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.users.total || 0) / (state.users.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    users: state.users.users,
    authUser: state.auth.data,
    bulkUsersCreateResults: state.users.bulkUsersCreateResults,
    sessionTimeout: state.auth.settings?.sessionTimeout,
    licensing: state.setting.licensing,
    isSelfRegistrationEnabled: state.auth.settings?.isSelfRegistrationEnabled,
    bulkCreatingUsers: createLoadingSelector([BULK_USERS_CREATE_PREFIX])(state),
    bulkCreatingUsersError: createErrorMessageSelector([BULK_USERS_CREATE_PREFIX])(state),
    loadingUsersList: createLoadingSelector([FETCH_USERS_LIST_PREFIX])(state),
    updatingUserStatus: createLoadingSelector([CHANGE_USER_STATUS_PREFIX])(state),
    updatingUser: createLoadingSelector([UPDATE_USER_PREFIX])(state),
    deletingUser: createLoadingSelector([DELETE_USER_PREFIX])(state),
    errorOnDeletingUser: createErrorMessageSelector([DELETE_USER_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getUsersList,
  bulkUsersCreate,
  clearBulkUsersCreateResults,
  updateUser,
  deleteUser,
  changeSetting,
  changeUserStatus,
  changeUsersRole,
  updateUserPermissions,
  updateAllUsersPermissions,
  getSettings,
  getSelfRegistrationStatus,
  resetUserMFA,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent);
