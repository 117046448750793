import {
  SocketService,
  UserApiService,
  ToastrService,
  SoundService,
  StorageService,
} from "../services";
import store from "../store";
import {
  constantMessages,
  SocketMessageType,
  callStatuses,
  callStatusesForParticipant,
  userStatuses,
  userRoles,
  systemMessages,
  appSounds,
} from "../config";
import {
  FETCH_CONTACTS_LIST_SUCCESS_ACTION,
  UPDATE_CONTACT_ACTION,
  FORCE_REMOVE_CONTACT_ACTION,
  ADD_NEW_REGISTERED_CONTACT_ACTION,
} from "./contacts";
import {
  FETCH_CURRENT_USER_SUCCESS_ACTION,
  forcedLogout,
  CHANGE_USER_PERMISSIONS,
  logOut,
  UPDATE_APP_CONNECTION_STATUS,
  CHANGE_CURRENT_USER_ROLE_SUCCESS,
  VERIFY_MOBILE_REQUEST_ACTION,
  VERIFY_MOBILE_SUCCESS_ACTION,
  VERIFY_MOBILE_FAILURE_ACTION,
  updateUserToken,
} from "./auth";
import {
  CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION,
  UPDATE_CHAT_ROOM_SUCCESS_ACTION,
  getChatRoomsList,
  markChatRoomMessagesAsRead,
  ADD_RECEIVED_MESSAGE_ACTION,
  REMOVE_MESSAGE_FROM_CHAT_ROOM,
  MARK_MESSAGE_IMPORTANT,
  MARK_MESSAGE_IMPORTANT_AS_READ,
  ACKNOWLEDGE_MESSAGE,
} from "./chatRooms";
import {
  handleReceivedInitializedCall,
  handleReceivedInitializedDirectScreenShare,
  handleDeclinedCall,
  handleAcceptedCall,
  handleEndedCall,
  updateCallRoomParticipant,
  handleForceVoiceToggle,
  declineCall,
  endCall,
  UPDATE_CALL,
  END_CALL,
  UPDATE_CALL_TWID,
} from "./calls";
import { getChatRoomById } from "./chat";
import moment from "moment";
import {
  getTwilioToken,
  getTwilioVideoToken,
  sendSystemMessage,
} from "./twilio";
import { setAwayTimer, updateTokenInterval } from "../components/App";
import { getAcceptedCall } from "../helpers/calls";
import { isMobile } from "../helpers/function";
import { contactDisplayName } from "../helpers/chat";
import i18n from "../i18n";
import { UPDATE_APP_SETTINGS_SUCCESS_ACTION } from "./setting";

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const CHANGE_CHAT_STATUS_ACTION = "user/CHANGE_CHAT_STATUS_ACTION";

export const FETCH_USERS_LIST_PREFIX = "user/FETCH_USERS_LIST";
export const FETCH_USERS_LIST_REQUEST_ACTION =
  FETCH_USERS_LIST_PREFIX + "_REQUEST_ACTION";
export const FETCH_USERS_LIST_SUCCESS_ACTION =
  FETCH_USERS_LIST_PREFIX + "_SUCCESS_ACTION";
export const FETCH_USERS_LIST_FAILURE_ACTION =
  FETCH_USERS_LIST_PREFIX + "_FAILURE_ACTION";

export const CHANGE_USER_STATUS_PREFIX = "user/CHANGE_USER_STATUS";
export const CHANGE_USER_STATUS_REQUEST_ACTION =
  CHANGE_USER_STATUS_PREFIX + "_REQUEST_ACTION";
export const CHANGE_USER_STATUS_SUCCESS_ACTION =
  CHANGE_USER_STATUS_PREFIX + "_SUCCESS_ACTION";
export const CHANGE_USER_STATUS_FAILURE_ACTION =
  CHANGE_USER_STATUS_PREFIX + "_FAILURE_ACTION";

export const CHANGE_USER_ROLE_PREFIX = "user/CHANGE_USER_ROLE";
export const CHANGE_USER_ROLE_REQUEST_ACTION =
  CHANGE_USER_ROLE_PREFIX + "_REQUEST_ACTION";
export const CHANGE_USER_ROLE_SUCCESS_ACTION =
  CHANGE_USER_ROLE_PREFIX + "_SUCCESS_ACTION";
export const CHANGE_USER_ROLE_FAILURE_ACTION =
  CHANGE_USER_ROLE_PREFIX + "_FAILURE_ACTION";

export const CREATE_USER_PREFIX = "user/CREATE_USER";
export const CREATE_USER_REQUEST_ACTION =
  CREATE_USER_PREFIX + "_REQUEST_ACTION";
export const CREATE_USER_SUCCESS_ACTION =
  CREATE_USER_PREFIX + "_SUCCESS_ACTION";
export const CREATE_USER_FAILURE_ACTION =
  CREATE_USER_PREFIX + "_FAILURE_ACTION";

export const BULK_USERS_CREATE_PREFIX = "user/BULK_USERS_CREATE";
export const BULK_USERS_CREATE_REQUEST_ACTION =
  BULK_USERS_CREATE_PREFIX + "_REQUEST_ACTION";
export const BULK_USERS_CREATE_SUCCESS_ACTION =
  BULK_USERS_CREATE_PREFIX + "_SUCCESS_ACTION";
export const BULK_USERS_CREATE_FAILURE_ACTION =
  BULK_USERS_CREATE_PREFIX + "_FAILURE_ACTION";

export const CLEAR_BULK_USERS_CREATE_RESULTS =
  "user/CLEAR_BULK_USERS_CREATE_RESULTS";

export const UPDATE_USER_PREFIX = "user/UPDATE_USER";
export const UPDATE_USER_REQUEST_ACTION =
  UPDATE_USER_PREFIX + "_REQUEST_ACTION";
export const UPDATE_USER_SUCCESS_ACTION =
  UPDATE_USER_PREFIX + "_SUCCESS_ACTION";
export const UPDATE_USER_FAILURE_ACTION =
  UPDATE_USER_PREFIX + "_FAILURE_ACTION";

export const CHANGE_USER_AVATAR_PREFIX = "user/CHANGE_USER_AVATAR";
export const CHANGE_USER_AVATAR_REQUEST_ACTION =
  CHANGE_USER_AVATAR_PREFIX + "_REQUEST_ACTION";
export const CHANGE_USER_AVATAR_SUCCESS_ACTION =
  CHANGE_USER_AVATAR_PREFIX + "_SUCCESS_ACTION";
export const CHANGE_USER_AVATAR_FAILURE_ACTION =
  CHANGE_USER_AVATAR_PREFIX + "_FAILURE_ACTION";

export const REMOVE_USER_AVATAR_PREFIX = "user/REMOVE_USER_AVATAR";
export const REMOVE_USER_AVATAR_REQUEST_ACTION =
  REMOVE_USER_AVATAR_PREFIX + "_REQUEST_ACTION";
export const REMOVE_USER_AVATAR_SUCCESS_ACTION =
  REMOVE_USER_AVATAR_PREFIX + "_SUCCESS_ACTION";
export const REMOVE_USER_AVATAR_FAILURE_ACTION =
  REMOVE_USER_AVATAR_PREFIX + "_FAILURE_ACTION";

export const GET_USER_BY_ID_PREFIX = "user/GET_USER_BY_ID";
export const GET_USER_BY_ID_REQUEST_ACTION =
  GET_USER_BY_ID_PREFIX + "_REQUEST_ACTION";
export const GET_USER_BY_ID_SUCCESS_ACTION =
  GET_USER_BY_ID_PREFIX + "_SUCCESS_ACTION";
export const GET_USER_BY_ID_FAILURE_ACTION =
  GET_USER_BY_ID_PREFIX + "_FAILURE_ACTION";

export const DELETE_USER_PREFIX = "user/DELETE_USER";
export const DELETE_USER_REQUEST_ACTION =
  DELETE_USER_PREFIX + "_REQUEST_ACTION";
export const DELETE_USER_SUCCESS_ACTION =
  DELETE_USER_PREFIX + "_SUCCESS_ACTION";
export const DELETE_USER_FAILURE_ACTION =
  DELETE_USER_PREFIX + "_FAILURE_ACTION";

export const UPDATE_USER_PERMISSIONS_PREFIX = "user/UPDATE_USER_PERMISSIONS";
export const UPDATE_USER_PERMISSIONS_REQUEST_ACTION =
  UPDATE_USER_PERMISSIONS_PREFIX + "_REQUEST_ACTION";
export const UPDATE_USER_PERMISSIONS_SUCCESS_ACTION =
  UPDATE_USER_PERMISSIONS_PREFIX + "_SUCCESS_ACTION";
export const UPDATE_USER_PERMISSIONS_FAILURE_ACTION =
  UPDATE_USER_PERMISSIONS_PREFIX + "_FAILURE_ACTION";

export const UPDATE_ALL_USERS_PERMISSIONS_PREFIX =
  "user/UPDATE_ALL_USERS_PERMISSIONS";
export const UPDATE_ALL_USERS_PERMISSIONS_REQUEST_ACTION =
  UPDATE_ALL_USERS_PERMISSIONS_PREFIX + "_REQUEST_ACTION";
export const UPDATE_ALL_USERS_PERMISSIONS_SUCCESS_ACTION =
  UPDATE_ALL_USERS_PERMISSIONS_PREFIX + "_SUCCESS_ACTION";
export const UPDATE_ALL_USERS_PERMISSIONS_FAILURE_ACTION =
  UPDATE_ALL_USERS_PERMISSIONS_PREFIX + "_FAILURE_ACTION";

export const GET_USER_LOGS_PREFIX = "user/GET_USER_LOGS";
export const GET_USER_LOGS_REQUEST_ACTION =
  GET_USER_LOGS_PREFIX + "_REQUEST_ACTION";
export const GET_USER_LOGS_SUCCESS_ACTION =
  GET_USER_LOGS_PREFIX + "_SUCCESS_ACTION";
export const GET_USER_LOGS_FAILURE_ACTION =
  GET_USER_LOGS_PREFIX + "_FAILURE_ACTION";

export const EXPORT_USER_LOGS_PREFIX = "user/EXPORT_USER_LOGS";
export const EXPORT_USER_LOGS_REQUEST_ACTION =
  EXPORT_USER_LOGS_PREFIX + "_REQUEST_ACTION";
export const EXPORT_USER_LOGS_SUCCESS_ACTION =
  EXPORT_USER_LOGS_PREFIX + "_SUCCESS_ACTION";
export const EXPORT_USER_LOGS_FAILURE_ACTION =
  EXPORT_USER_LOGS_PREFIX + "_FAILURE_ACTION";

export const SET_CONNECTION_LOST_TIME = "user/SET_CONNECTION_LOST_TIME";
export const SET_CONNECTION_RECOVERED_TIME =
  "user/SET_CONNECTION_RECOVERED_TIME";

export const SET_USER_LOGS_PAGE = "logs/SET_USER_LOGS_PAGE";

export const UPDATE_USER_LOGS = "logs/UPDATE_USER_LOGS";

export const RESET = "user/RESET";

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  users: [],
  total: 0,
  totalLogs: 0,
  user: {},
  logs: [],
  connectionLostTime: null,
  connectionRecoveredTime: null,
  bulkUsersCreateResults: null,
  userLogsPage: 0,
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  const users = [...state.users];
  const user = { ...state.user };
  const userIndex =
    action.payload && action.payload.id
      ? users.findIndex((user) => user.id === action.payload.id)
      : null;
  switch (action.type) {
    case UPDATE_USER_LOGS:
      return {
        ...state,
        logs: [
          {
            ...action.payload,
            createdAt: new Date().getTime() / 1000,
            updatedAt: new Date().getTime() / 1000,
          },
          ...state.logs,
        ],
      };
    case SET_USER_LOGS_PAGE:
      return {
        ...state,
        userLogsPage: action.payload.page,
      };
    case FETCH_USERS_LIST_SUCCESS_ACTION:
      return {
        ...state,
        total: action.payload.total,
        pageSize: action.payload.pageSize,
        users: action.payload.users || [],
      };
    case CREATE_USER_SUCCESS_ACTION:
    case UPDATE_USER_SUCCESS_ACTION:
    case GET_USER_BY_ID_SUCCESS_ACTION:
      const updUsers = [...state.users];
      const updUserIndex = updUsers.findIndex(
        (user) => user.id === action.payload.user.id
      );

      if (updUserIndex !== -1) {
        updUsers[updUserIndex] = {
          ...updUsers[updUserIndex],
          ...action.payload.user,
        };
      }
      return {
        ...state,
        user: action.payload.user,
        users: updUsers,
      };
    case CHANGE_USER_STATUS_SUCCESS_ACTION:
    case UPDATE_USER_PERMISSIONS_SUCCESS_ACTION:
    case CHANGE_USER_ROLE_SUCCESS_ACTION:
      const index = users.findIndex(
        (user) => user.id === action.payload.user.id
      );
      if (index !== -1) {
        users[index] = { ...users[index], ...action.payload.user };
      }
      return {
        ...state,
        users,
      };
    case UPDATE_ALL_USERS_PERMISSIONS_SUCCESS_ACTION:
      return {
        ...state,
        users: users.map((user) => ({
          ...user,
          permissions: { ...user.permissions, ...action.payload.data },
        })),
      };
    case CHANGE_USER_AVATAR_SUCCESS_ACTION:
      if (users[userIndex]) {
        users[userIndex].avatar = action.payload.data.path;
      }
      if (user?.id === action.payload.id) {
        user.avatar = action.payload.data.path;
      }
      return {
        ...state,
        users,
        user,
      };
    case REMOVE_USER_AVATAR_SUCCESS_ACTION:
      if (users[userIndex]) {
        users[userIndex].avatar = null;
      }
      return {
        ...state,
        users,
      };
    case CHANGE_CHAT_STATUS_ACTION:
      return {
        ...state,
        isStatusSettedManually: action.payload,
      };
    case GET_USER_LOGS_SUCCESS_ACTION:
      return {
        ...state,
        logs: action.payload.logs,
        totalLogs: action.payload.total,
      };
    case SET_CONNECTION_LOST_TIME:
      return {
        ...state,
        connectionLostTime: action.payload,
      };
    case SET_CONNECTION_RECOVERED_TIME:
      return {
        ...state,
        connectionRecoveredTime: action.payload,
      };
    case BULK_USERS_CREATE_SUCCESS_ACTION:
      return {
        ...state,
        bulkUsersCreateResults: action.payload,
      };
    case CLEAR_BULK_USERS_CREATE_RESULTS:
      return {
        ...state,
        bulkUsersCreateResults: null,
      };
    case RESET:
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function changeChatStatusAction(userId, newStatus, isManually = false) {
  return (dispatch) => {
    try {
      const userService = new UserApiService();
      userService.changeChatStatus(userId, newStatus);
      dispatch({ type: CHANGE_CHAT_STATUS_ACTION, payload: isManually });
    } catch (err) {
      const message =
        (err && err.message) || constantMessages.defaultErrorMessage;
      dispatch({ type: FETCH_USERS_LIST_FAILURE_ACTION, payload: { message } });
      ToastrService.error(message);
    }
  };
}

export function getUsersList(params) {
  return (dispatch) => {
    dispatch({ type: FETCH_USERS_LIST_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .getUsersList({ sortBy: "firstName", order: "asc", ...params })
      .then(({ data = {} }) => {
        const { results: users, total, pageSize } = data;
        dispatch({
          type: FETCH_USERS_LIST_SUCCESS_ACTION,
          payload: { users, total, pageSize },
        });
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: FETCH_USERS_LIST_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function changeUserStatus(id, status) {
  return (dispatch) => {
    dispatch({ type: CHANGE_USER_STATUS_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService[status === "active" ? "activateUser" : "deactivateUser"](
      id
    )
      .then(({ data = {} }) => {
        dispatch({
          type: CHANGE_USER_STATUS_SUCCESS_ACTION,
          payload: { user: data.user || {} },
        });
        ToastrService.success(
          data.message || i18n.t('misc.success_user_status_changed')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: CHANGE_USER_STATUS_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function changeUsersRole(id, role) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_USER_ROLE_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .changeUsersRole({ id, role })
      .then(({ data = {} }) => {
        const user = data.user || {};
        if (user.id === getState().auth.data.id) {
          dispatch({
            type: CHANGE_CURRENT_USER_ROLE_SUCCESS,
            payload: { role: user.role },
          });
        }
        dispatch({ type: CHANGE_USER_ROLE_SUCCESS_ACTION, payload: { user } });
        ToastrService.success(
          data.message || i18n.t('misc.success_user_role_changed')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: CHANGE_USER_ROLE_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function createUser(data) {
  return (dispatch) => {
    dispatch({ type: CREATE_USER_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .createUser(data)
      .then(({ data = {} }) => {
        dispatch({
          type: CREATE_USER_SUCCESS_ACTION,
          payload: { user: data.user || {} },
        });
        ToastrService.success(
          data.message || i18n.t('misc.success_user_created')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_USER_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      });
  };
}

export function bulkUsersCreate(file) {
  return (dispatch) => {
    dispatch({ type: BULK_USERS_CREATE_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .bulkUsersCreate(file)
      .then(({ data = {} }) => {
        if (data?.message === "Uploaded file is empty") {
          dispatch({
            type: BULK_USERS_CREATE_FAILURE_ACTION,
            payload: { message: data.message },
          });
          return;
        }
        dispatch({
          type: BULK_USERS_CREATE_SUCCESS_ACTION,
          payload: data.data?.results || [],
        });
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: BULK_USERS_CREATE_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function updateUser(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .updateUser(data)
      .then(({ data = {} }) => {
        dispatch({
          type: UPDATE_USER_SUCCESS_ACTION,
          payload: { user: data.user || {} },
        });
        if (data.user?.id) {
          dispatch({
            type: UPDATE_CONTACT_ACTION,
            payload: { id: data.user.id, contact: data.user, updateAll: true },
          });
        }
        ToastrService.success(
          data.message || i18n.t('misc.success_user_update')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_USER_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      });
  };
}

export function verifyUpdatedMobileAction(data) {
  return (dispatch) => {
    dispatch({ type: VERIFY_MOBILE_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .verifyUpdatedMobile(data)
      .then(() => {
        dispatch({ type: VERIFY_MOBILE_SUCCESS_ACTION });
        ToastrService.success(i18n.t('misc.success_phone_verified'));
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: VERIFY_MOBILE_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      });
  };
}

export function changeUserAvatar(file, id) {
  return (dispatch) => {
    dispatch({ type: CHANGE_USER_AVATAR_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .changeUserAvatar(file, id)
      .then(({ data = {} }) => {
        dispatch({
          type: CHANGE_USER_AVATAR_SUCCESS_ACTION,
          payload: { data, id },
        });
        ToastrService.success(
          data.message || i18n.t('misc.succes_user_avatar_update')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: CHANGE_USER_AVATAR_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function removeUserAvatar(id) {
  return (dispatch) => {
    dispatch({ type: REMOVE_USER_AVATAR_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .removeUserAvatar(id)
      .then((data = {}) => {
        dispatch({ type: REMOVE_USER_AVATAR_SUCCESS_ACTION, payload: { id } });
        ToastrService.success(
          data.message || i18n.t('misc.succes_user_avatar_remove')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: REMOVE_USER_AVATAR_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function getUserById(id) {
  return (dispatch) => {
    dispatch({ type: GET_USER_BY_ID_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .getUserById(id)
      .then(({ user = {} }) => {
        dispatch({ type: GET_USER_BY_ID_SUCCESS_ACTION, payload: { user } });
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_USER_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .deleteUser(id)
      .then(() => {
        dispatch({ type: DELETE_USER_SUCCESS_ACTION });
        dispatch({
          type: UPDATE_CONTACT_ACTION,
          payload: { id, contact: { deleted: true } },
        });
        ToastrService.success(i18n.t('misc.success_user_removed'));
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_USER_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      });
  };
}

export function updateUserPermissions(requestData) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_PERMISSIONS_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .updateUserPermissions(requestData)
      .then(({ data = {} }) => {
        const { id } = getState().auth.data;
        if (id === data.user?.id) {
          dispatch({
            type: CHANGE_USER_PERMISSIONS,
            payload: { ...data.user.permissions },
          });
        }
        dispatch({
          type: UPDATE_USER_PERMISSIONS_SUCCESS_ACTION,
          payload: { user: data.user || {} },
        });
        ToastrService.success(
          i18n.t('misc.user_permission_update')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: UPDATE_USER_PERMISSIONS_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function resetUserMFA(userId) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_PERMISSIONS_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .resetUserMFA({ userId })
      .then(({ data = {} }) => {
        const { id } = getState().auth.data;
        if (id === data.user?.id) {
          dispatch({
            type: CHANGE_USER_PERMISSIONS,
            payload: { ...data.user.permissions },
          });
        }
        dispatch({
          type: UPDATE_USER_PERMISSIONS_SUCCESS_ACTION,
          payload: { user: data.user || {} },
        });
        ToastrService.success(
          i18n.t('misc.user_permission_update')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: UPDATE_USER_PERMISSIONS_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function updateAllUsersPermissions(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ALL_USERS_PERMISSIONS_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService
      .updateAllUsersPermissions(data)
      .then(() => {
        dispatch({
          type: UPDATE_ALL_USERS_PERMISSIONS_SUCCESS_ACTION,
          payload: { data },
        });
        ToastrService.success(
          i18n.t('misc.users_permission_update')
        );
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({
          type: UPDATE_ALL_USERS_PERMISSIONS_FAILURE_ACTION,
          payload: { message },
        });
        ToastrService.error(message);
      });
  };
}

export function getUserLogsList({
  page = 0,
  pageSize = 10,
  sortBy = "createdAt",
  order = "desc",
  keyword = "",
  to = "",
  from = "",
}) {
  return (dispatch, getState) => {
    const userData = getState().auth?.data;
    const userRole = userData?.role?.name || userData?.role;
    if (userRole === userRoles.ADMIN || userRole === userRoles.FACILITY_ADMIN) {
      dispatch({ type: GET_USER_LOGS_REQUEST_ACTION });
      const userService = new UserApiService();
      return userService
        .getUserLogs({ page, pageSize, sortBy, order, keyword, to, from })
        .then(({ data }) => {
          const logs = data.results || [];
          dispatch({
            type: GET_USER_LOGS_SUCCESS_ACTION,
            payload: { logs, total: data.total },
          });
        })
        .catch((err = {}) => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({
            type: GET_USER_LOGS_FAILURE_ACTION,
            payload: { message },
          });
          ToastrService.error(message);
        });
    }
  };
}

export function exportUserLogsList({
  page = 0,
  pageSize = 10,
  sortBy = "createdAt",
  order = "desc",
  keyword = "",
  to = "",
  from = "",
  format = "csv",
}) {
  return (dispatch, getState) => {
    const userData = getState().auth?.data;
    const userRole = userData?.role?.name || userData?.role;
    if (userRole === userRoles.ADMIN || userRole === userRoles.FACILITY_ADMIN) {
      dispatch({ type: EXPORT_USER_LOGS_REQUEST_ACTION });
      const userService = new UserApiService();
      return userService
        .exportUserLogs({
          page,
          pageSize,
          sortBy,
          order,
          keyword,
          to,
          from,
          format,
        })
        .then((data) => {
          try {
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", "logs.csv");
            tempLink.click();
          } catch (e) {
            throw Error(e);
          }
          dispatch({ type: EXPORT_USER_LOGS_SUCCESS_ACTION });
        })
        .catch((err = {}) => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({
            type: EXPORT_USER_LOGS_FAILURE_ACTION,
            payload: { message },
          });
          ToastrService.error(message);
        });
    }
  };
}

export function setUserLogsPage(page = 0) {
  return (dispatch) => {
    dispatch({ type: SET_USER_LOGS_PAGE, payload: { page } });
  };
}

export function clearBulkUsersCreateResults() {
  return (dispatch) => {
    dispatch({ type: CLEAR_BULK_USERS_CREATE_RESULTS });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({ type: RESET });
  };
}

export function addSocketEvents() {
  SocketService.addEventListeners(
    SocketMessageType.CHAT_STATUS_CHANGED,
    ({ userId, newStatus }) => {
      const state = store.getState() || {};
      const data = { ...state.auth.data };
      if (data && data.id === userId) {
        const { token, role } = state.auth;
        data.chatStatus = newStatus;
        store.dispatch({
          type: FETCH_CURRENT_USER_SUCCESS_ACTION,
          payload: { token, data, role },
        });
      } else if (state.contacts.list && state.contacts.list.length) {
        const contactIndex = state.contacts.list.findIndex(
          (el) => el.id === userId
        );
        if (contactIndex !== -1) {
          const newList = [...state.contacts.list];
          newList[contactIndex].chatStatus = newStatus;
          store.dispatch({
            type: FETCH_CONTACTS_LIST_SUCCESS_ACTION,
            payload: { contacts: newList },
          });
        }
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.USER_DATA_UPDATED,
    ({ userId: id, data: contact }) => {
      if (id !== store.getState().auth.data.id) {
        store.dispatch({
          type: UPDATE_CONTACT_ACTION,
          payload: { id, contact },
        });
      }
    }
  );

  SocketService.addEventListeners(SocketMessageType.USER_LOG_UPDATED, () => {
    store.dispatch(
      getUserLogsList({ page: store.getState().users.userLogsPage })
    );
  });

  SocketService.addEventListeners(
    SocketMessageType.USER_IS_ONLINE,
    ({ userId: id }) => {
      store.dispatch({
        type: UPDATE_CONTACT_ACTION,
        payload: { id, contact: { connected: true } },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.USER_IS_OFFLINE,
    ({ userId: id }) => {
      store.dispatch({
        type: UPDATE_CONTACT_ACTION,
        payload: { id, contact: { connected: false } },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.FORCED_LOG_OUT,
    ({ userId: id, reason }) => {
      const state = store.getState() || {};
      const data = { ...state.auth.data };
      if (data?.id === id) {
        store.dispatch(
          forcedLogout(
            `Your account's ${reason || "data had been changed"
            }, please keep data that are not saved because you will be logged out in 10 seconds`
          )
        );
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.FORCED_DELETE,
    ({ userId: id }) => {
      const state = store.getState() || {};
      const data = { ...state.auth.data };
      if (data?.id === id) {
        store.dispatch(
          forcedLogout(
            "Your account has been removed, please keep data that are not saved because you will be logged out in 10 seconds"
          )
        );
      } else {
        store.dispatch({ type: FORCE_REMOVE_CONTACT_ACTION, payload: { id } });
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.DUPLICATE_SESSION,
    ({ userId: id }) => {
      const state = store.getState() || {};
      const data = { ...state.auth.data };
      if (data?.id === id) {
        store.dispatch(
          forcedLogout(
            "You have logged in on another device, please keep data that are not saved because you will be logged out in 10 seconds"
          )
        );
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CONVERSATION_CREATED,
    ({ conversation: chatRoom }) => {
      const { id } = store.getState().auth.data;
      if (chatRoom?.owner === id) {
        return;
      }
      const user = chatRoom.participants.find(
        (participant) => participant.id === id
      );
      if (user) {
        store.dispatch({
          type: CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION,
          payload: { chatRoom, isInvited: true },
        });
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CONVERSATION_UPDATED,
    ({ conversation: chatRoom }) => {
      console.log("CONVERSATION_UPDATED", chatRoom);
      const { id } = store.getState().auth.data;
      if (chatRoom?.owner === id) {
        return store.dispatch(getChatRoomsList());
      }
      const user = chatRoom.participants.find(
        (participant) => participant.id === id
      );
      if (user) {
        store.dispatch({ type: UPDATE_CONTACT_ACTION, payload: { chatRoom } });
        store.dispatch(getChatRoomsList());
        store.dispatch(getChatRoomById(id, chatRoom.twId));
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CHAT_ROOM_DELETED,
    (twId) => {
      const chatRoom = store
        .getState()
        .chatRooms.list.find((c) => c.twId === twId);
      if (chatRoom) {
        return store.dispatch(getChatRoomsList());
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.USER_PERMISSION_CHANGED_SERVER,
    (data) => {
      console.log("%c USER_PERMISSION_CHANGED_SERVER", "color: #FFFF00", data);
      const { id, permissions } = data;
      const userId = store.getState().auth.data.id;

      if (!permissions) {
        return;
      }

      if (id === userId || !id) {
        store.dispatch({ type: CHANGE_USER_PERMISSIONS, payload: permissions });
      } else {
        store.dispatch({
          type: UPDATE_USER_PERMISSIONS_SUCCESS_ACTION,
          payload: { user: { id, permissions } },
        });

        store.dispatch({
          type: UPDATE_CONTACT_ACTION,
          payload: { id, contact: { permissions } },
        });
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.DIRECT_SCREEN_SHARE_INITIALIZED_SERVER,
    (data, chatRoom) => {
      if (!data) {
        return;
      }
      const { participants } = data;
      const { id } = store.getState().auth.data;
      if (!!participants?.find((participant) => participant.id === id)) {
        store.dispatch(handleReceivedInitializedDirectScreenShare(data));
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_INITIALIZED_SERVER,
    (data, chatRoom) => {
      console.log("%c CALL_INITIALIZED_SERVER", "color: #FFFF00", data);
      if (!data) {
        return;
      }
      const { participants } = data;
      const { id, permissions } = store.getState().auth.data;
      if (!!participants?.find((participant) => participant.id === id)) {
        const isVideoCall = data.participants?.find(
          (participant) => participant.id === data.initializerId
        )?.video;
        if (
          (isVideoCall && permissions.video) ||
          (!isVideoCall && permissions.voice)
        ) {
          store.dispatch(handleReceivedInitializedCall(data));
        }
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_DECLINED_SERVER,
    (data) => {
      console.log("%c CALL_DECLINED_SERVER", "color: #FFFF00", data);
      if (!data) {
        return;
      }
      const { status, eventOwnerId, twId } = data;
      const { id } = store.getState().auth.data;
      if (status === callStatuses.ENDED || eventOwnerId === id) {
        store.dispatch(handleDeclinedCall(twId));
      } else {
        const { list } = store.getState().contacts;
        const newJoinedContact = list.find(
          (contact) => contact.id === data.eventOwnerId
        );
        if (newJoinedContact?.fullName) {
          ToastrService.info(`${newJoinedContact.fullName} has declined`);
        }
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_ACCEPTED_SERVER,
    (data) => {
      console.log("%c CALL_ACCEPTED_SERVER", "color: #FFFF00", data);
      if (!data) {
        return;
      }
      const { status, participants } = data;
      const { id } = store.getState().auth.data;
      const call = store
        .getState()
        .calls.calls.find((c) => c.twId === data.twId);
      if (call && call.participants.length !== data.participants.length) {
        store.dispatch({
          type: UPDATE_CALL,
          payload: { twId: data.twId, participants: data.participants },
        });
      }

      if (
        status === callStatuses.STARTED &&
        participants?.find((participant) => participant?.id === id)?.status ===
        callStatusesForParticipant.ACCEPTED
      ) {
        store.dispatch(handleAcceptedCall(data));
      } else {
        store.dispatch(getChatRoomsList());
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_ENDED_SERVER,
    (data) => {
      console.log("%c CALL_ENDED_SERVER", "color: #FFFF00", data);
      if (!data) {
        return;
      }

      const { status, participants, twId } = data;
      const { id } = store.getState().auth.data;
      const idleTimer = store.getState().auth.idleTimer;

      if (idleTimer) {
        idleTimer.reset();
      }

      if (
        status === callStatuses.ENDED ||
        participants?.find((participant) => participant?.id === id)?.status ===
        callStatusesForParticipant.DROPPED
      ) {
        store.dispatch(handleEndedCall(data));
        if (status !== callStatuses.ENDED) {
          store.dispatch(getChatRoomsList());
        }
        store.dispatch({
          type: UPDATE_CHAT_ROOM_SUCCESS_ACTION,
          payload: { chatRoom: { twId, call: null } },
        });
      } else {
        store.dispatch(
          updateCallRoomParticipant(
            data.twId,
            data.eventOwnerId,
            participants?.find(
              (participant) => participant.id === data.eventOwnerId
            )
          )
        );
        const { list } = store.getState().contacts;
        const newJoinedContact = list.find(
          (contact) => contact.id === data.eventOwnerId
        );
        if (newJoinedContact?.fullName) {
          ToastrService.info(i18n.t('misc.has_left', { fullName: newJoinedContact.fullName }));
        }
      }
    }
  );

  SocketService.addEventListeners("unauthorized", () => {
    console.log(
      "SocketService: unauthorized event, dispatching updateUserToken()"
    );
    store.dispatch(updateUserToken());
  });

  SocketService.addEventListeners(
    SocketMessageType.SYSTEM_MESSAGE_RECEIVED,
    (message) => {
      store.dispatch({
        type: ADD_RECEIVED_MESSAGE_ACTION,
        payload: {
          conversationId: message.twId,
          playSoundOnNotification: false,
          messages: [
            {
              body: message.message.body,
              attributes: message.message.attributes,
              from: { id: message.message.attributes?.event?.data?.initiator },
              id: message.timetoken,
              timetoken: message.timetoken,
            },
          ],
        },
      });

      const { selectedChatRoomTWId } = store.getState().chatRooms;

      if (selectedChatRoomTWId === message.twId) {
        store.dispatch(markChatRoomMessagesAsRead(selectedChatRoomTWId));
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_PARTICIPANT_MUTE_SERVER,
    (data) => {
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_PARTICIPANT_UNMUTE_SERVER,
    (data) => {
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_PARTICIPANT_MUTE_DURING_SCREEN_SHARE_SERVER,
    (data) => {
      console.log(
        "CALL_PARTICIPANT_MUTE_DURING_SCREEN_SHARE_SERVER DATA",
        data
      );
      const me = store.getState().auth.data;
      const isEventOwner = data.eventOwnerId === me.id;
      const contacts = store.getState().contacts.list;
      const initializerName = contacts.find(
        (c) => c.id === data.eventOwnerId
      )?.fullName;
      const onGoingCall = store
        .getState()
        .calls.calls.find((call) => call.status === callStatuses.STARTED);
      if (!isEventOwner && onGoingCall) {
        data.participants.forEach((p) => {
          if (
            p.voiceDuringScreenShare !==
            onGoingCall.participants.find(
              (oldParticipant) => oldParticipant.id === p.id
            ).voiceDuringScreenShare
          ) {
            const participantName = contacts.find(
              (c) => c.id === p.id
            )?.fullName;
            ToastrService.info(`${initializerName} has muted ${participantName || contactDisplayName(me)}`
            );
          }
        });
      }
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.CALL_PARTICIPANT_UNMUTE_DURING_SCREEN_SHARE_SERVER,
    (data) => {
      console.log(
        "CALL_PARTICIPANT_UNMUTE_DURING_SCREEN_SHARE_SERVER DATA",
        data
      );
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });

      const { id } = store.getState().auth.data;

      if (
        data.eventOwnerId !== id &&
        data.participants.find((participant) => participant.id === id)
          ?.voiceDuringScreenShare
      ) {
        store.dispatch(handleForceVoiceToggle(data.twId));
      }
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.START_SCREEN_SHARE_SERVER,
    (data) => {
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.END_SCREEN_SHARE_SERVER,
    (data) => {
      console.log("END_SCREEN_SHARE_SERVER DATA", data);
      store.dispatch({
        type: UPDATE_CALL,
        payload: { twId: data.twId, participants: data.participants },
      });
    }
  );

  SocketService.addEventListeners(SocketMessageType.REMOVE_MESSAGE, (data) => {
    const { messageId, twId } = data;
    store.dispatch({
      type: REMOVE_MESSAGE_FROM_CHAT_ROOM,
      payload: { messageId, twId },
    });
  });

  SocketService.addEventListeners(
    SocketMessageType.CHAT_ROOM_UPDATED,
    (data) => {
      store.dispatch({
        type: UPDATE_CHAT_ROOM_SUCCESS_ACTION,
        payload: { chatRoom: data },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.MarkImportantMessage,
    (data) => {
      store.dispatch({
        type: MARK_MESSAGE_IMPORTANT,
        payload: { ...data },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.MessageReadNotification,
    (data) => {
      store.dispatch({
        type: MARK_MESSAGE_IMPORTANT_AS_READ,
        payload: { ...data },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.AcknowledgeMessage,
    (data) => {
      store.dispatch({
        type: ACKNOWLEDGE_MESSAGE,
        payload: { ...data },
      });
    }
  );

  SocketService.addEventListeners(
    SocketMessageType.NEW_USER_REGISTERED_SUCCESSFULLY,
    (data) => {
      store.dispatch({
        type: ADD_NEW_REGISTERED_CONTACT_ACTION,
        payload: data,
      });
    }
  );


  SocketService.addEventListeners(
    SocketMessageType.LicensingSettingsUpdate,
    (data) => {
      store.dispatch({
        type: UPDATE_APP_SETTINGS_SUCCESS_ACTION,
        payload: { licensing: data },
      });
    }
  );

  SocketService.addEventListeners(SocketMessageType.PONG, () => {
    //Pong received
  });
}

window.addEventListener("beforeunload", (e) => {
  const { isStatusSettedManually } = store.getState().users;
  const userId = store.getState().auth.data.id;
  const { calls } = store.getState().calls;
  const acceptedCall = getAcceptedCall(calls, userId);
  if (acceptedCall) {
    acceptedCall.callRoom && acceptedCall.callRoom.disconnect();

    // system message
    const callTimeInSeconds = acceptedCall.startedAt
      ? moment
        .duration(moment(new Date()).diff(moment(acceptedCall.startedAt)))
        ?.asSeconds()
      : 0;
    const callDuration = moment
      .utc(callTimeInSeconds * 1000)
      .format(callTimeInSeconds >= 3600 ? "HH:mm:ss" : "mm:ss");
    const isVideoCall = acceptedCall.participants?.find(
      (participant) => participant.id === userId
    )?.video;
    const eventName = isVideoCall
      ? systemMessages.VIDEO_CALL_ENDED
      : systemMessages.CALL_ENDED;
    const event = {
      name: eventName,
      data: { twId: acceptedCall.twId, initiator: userId, callDuration },
    };

    store.dispatch(sendSystemMessage(eventName, acceptedCall.twId, event));
  }

  if (!!userId) {
    if (!isStatusSettedManually) {
      const userService = new UserApiService();
      userService.changeChatStatus(userId, userStatuses[0].value);
    }
  }

  const storageService = new StorageService();
  const userSessions = storageService.checkUserSessions() || {};
  const removeIndex = userSessions[userId]?.findIndex((v) => v === "active");
  storageService.updateUserSessions({
    ...userSessions,
    [userId]: userSessions[userId]?.filter((i, index) => index !== removeIndex),
  });
});

let offlineActionsTimeout;

window.addEventListener("offline", () => {
  store.dispatch({ type: SET_CONNECTION_LOST_TIME, payload: moment() });
  store.dispatch({ type: UPDATE_APP_CONNECTION_STATUS, payload: true });

  const { calls, localTracks } = store.getState().calls;

  offlineActionsTimeout = setTimeout(() => {
    if (calls.length) {
      calls.forEach((call) => {
        if (
          call.type === "direct" ||
          call.participants?.filter(
            (participant) =>
              participant.status === callStatusesForParticipant.ACCEPTED
          )?.length === 2
        ) {
          store.dispatch({
            type: UPDATE_CHAT_ROOM_SUCCESS_ACTION,
            payload: { chatRoom: { twId: call.twId, call: null } },
          });
          store.dispatch({ type: END_CALL, payload: { data: call } });
        }
      });
      SoundService.stop(appSounds.CALL_SOUND);
      localTracks.forEach((localTrack) => localTrack?.stop());
      store.dispatch({ type: UPDATE_CALL_TWID, payload: null });
    }
  }, 2000);
});

window.addEventListener("online", async () => {
  if (offlineActionsTimeout) {
    clearTimeout(offlineActionsTimeout);
  }
  store.dispatch({ type: SET_CONNECTION_RECOVERED_TIME, payload: moment() });
  store.dispatch({ type: UPDATE_APP_CONNECTION_STATUS, payload: false });

  const { token } = store.getState().auth;
  const {
    data: { sessionTimeout },
  } = store.getState().auth;
  const { connectionLostTime, connectionRecoveredTime } =
    store.getState().users;
  const idlenessTime = Math.round(
    connectionRecoveredTime.diff(connectionLostTime) / 1000
  );

  if (sessionTimeout && idlenessTime > sessionTimeout) {
    store.dispatch(logOut());
  } else {
    if (token) {
      store.dispatch(getTwilioToken(true, false));
      store.dispatch(getTwilioVideoToken(true));
      store.dispatch(getChatRoomsList());
    }
  }
});

window.addEventListener("storage", () => {
  const { id } = store.getState().auth.data;
  const storageService = new StorageService();
  const userSessions = storageService.checkUserSessions() || {};
  if (userSessions[id]?.length && !userSessions[id]?.includes("active")) {
    storageService.updateUserSessions({
      ...userSessions,
      [id]: [],
    });
    clearInterval(updateTokenInterval);
    clearTimeout(setAwayTimer);
    store.dispatch(logOut());
    ToastrService.info(
      "Your session has expired. Please log back in to continue."
    );
  }
});

document.addEventListener("visibilitychange", () => {
  if (isMobile()) {
    const { calls } = store.getState().calls;

    if (calls.length && document.hidden) {
      calls.forEach((call) =>
        call.status === callStatuses.STARTED
          ? store.dispatch(endCall(call.twId))
          : store.dispatch(declineCall(call.twId))
      );
    }
  }
});
