import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import '../../../index.scss';
import { Loader, UserAvatar } from "../../../../UIComponents";
import TableComponent from "../../../../../containers/UIComponents/TableContainer"
import SearchComponent from "../../../../UIComponents/SearchComponent";
import { appTheme, STATIC_BASE } from "../../../../../config";
import { withTranslation } from 'react-i18next';
import ResetOrDeleteUserModal from '../../Modals/ResetOrDeleteUserComponent';

class UnverifiedUsersComponent extends Component {
  fileInputRef = null;

  state = {
    showDeleteUserModal: false,
    defaultSessionTimeoutError: '',
    selectedUser: {},
    keyword: '',
    page: 0,
    pageSize: 10,
    sortBy: "firstName",
    order: "asc",
  };

  componentDidMount() {
    this.updateUsersList();
    this.setState({ defaultSessionTimeout: this.props.sessionTimeout || '', isSelfRegistrationEnabled: !!this.props.isSelfRegistrationEnabled })
  }

  componentDidUpdate(prevProps) {
    const { deletingUser, errorOnDeletingUser } = this.props,
      { deletingUser: prevDeletingUser } = prevProps,
      { showDeleteUserModal } = this.state;

    if (showDeleteUserModal && !deletingUser && prevDeletingUser && !errorOnDeletingUser) {
      this.updateUsersList();
      this.toggleDeleteUserModal();
    }
  }

  handleStatusChange = (status, user) => {
    this.props.changeUserStatus(user.id, status.value.toLowerCase())
  };

  handleRoleChange = (status, user) => {
    const newStatus = status.value.replace(/\s/g, '');
    if (newStatus !== user.role.name) {
      this.props.changeUsersRole(user.id, newStatus)
    }
  }

  handleBulkUsersCreateResultsModalClose = () => {
    this.setState({ showBulkUsersCreateResultsModal: false });
    this.props.clearBulkUsersCreateResults();
  }

  onDefaultSessionTimeoutChange = e => {
    const value = Number(e.target.value)

    if (typeof value === 'number' && !Number.isNaN(value)) {
      if (value < 0) {
        return this.setState({ defaultSessionTimeoutError: 'Input value should not be less than 0.' })
      } else if (value > 100000) {
        return this.setState({ defaultSessionTimeoutError: 'Input value should not be greater than 100000.' })
      }
      this.setState({ defaultSessionTimeout: value })
    } else {
      this.setState({ defaultSessionTimeoutError: 'Input value should be a number.' })
    }
  }

  handleDefaultSessionTimeout = () => {
    const value = this.state.defaultSessionTimeout
    this.props.changeSetting({ key: 'sessionTimeout', value })
    this.updateUsersList();
  }

  updateSelfRegistrationStatus = () => {
    const value = this.state.isSelfRegistrationEnabled
    this.props.changeSetting({ key: 'isSelfRegistrationEnabled', value })
  }

  handlePermissionChange = (key, status, user) => {
    const newStatus = status.value === 'Active';
    if (newStatus !== user?.permissions?.[key]) {
      this.props.updateUserPermissions({
        id: user.id,
        [key]: newStatus,
      })
    }
  };

  updateAllUsersPermissions = (name) => {
    this.props.updateAllUsersPermissions({
      [name]: this.state[`${name}Permission`] === 'Activate'
    })
  };

  toggleResetPasswordModal = (selectedUser = {}) => {
    this.setState({ showResetPasswordModal: !this.state.showResetPasswordModal, selectedUser })
  };

  toggleDeleteUserModal = (selectedUser = {}) => {
    this.setState({ showDeleteUserModal: !this.state.showDeleteUserModal, selectedUser })
  };

  handleSelectFieldChange = (value, name) => {
    this.setState({ [name]: value })
  };

  updateSearchKeyword = (keyword) => {
    this.setState({ keyword, page: 0 }, this.updateUsersList)
  };

  updateUsersList = () => {
    const { keyword, page, pageSize, sortBy, order } = this.state;

    this.props.getUsersList({
      unverified: true,
      keyword: _.trim(keyword || ""),
      page,
      pageSize,
      sortBy,
      order
    })
  };

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page }, this.updateUsersList);
  };

  renderTableRow = user => {
    const { t } = this.props;

    let sessionTimeout = user.sessionTimeout || '-';
    if (user.isDefaultSessionTimeout) {
      sessionTimeout = (<span className="badge badge-secondary">{t('default')}</span>)
    }

    const phone = `${user.phoneCountryCode || ""}${user.phone}`;

    return <>
      <td className="avatar-cell">
        <div className="avatar">
          <div className="avatar-img-container">
            <UserAvatar user={user} className="small" />
          </div>
        </div>
      </td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email} {user.emailVerified ? <div className='yes'>(Verified)</div> : <div className='no'>(Not verified)</div>}</td>
      <td>{phone} {user.phoneVerified ? <div className='yes'>(Verified)</div> : <div className='no'>(Not verified)</div>}</td>
      <td>{sessionTimeout}</td>
      <td>
        <div className="d-flex actions">
          <i title={t('auth.del_user')} className="mr-3" onClick={() => this.toggleDeleteUserModal(user)}><img
            src={"/assets/images/icn-delete-user.svg"} alt='' /></i>
        </div>
      </td>
    </>
  };

  handleSortChange = (sortBy, order) => {
    if (sortBy === this.state.sortBy && order === this.state.order) {
      return
    }
    this.setState({ sortBy, order }, this.updateUsersList)
  };

  renderBulkUploadSection() {
    const { selectedTheme, t } = this.props;
    return (
      <div className="d-flex flex-column align-items-end">
        <div className={`d-flex align-items-center bulkUploadContainer${selectedTheme === appTheme.DARK ? ' dark-mode' : ''}`}>
          <a href={`${STATIC_BASE}/template.csv`}>{t('admin.download_cvs')}</a>
        </div>
      </div>
    )
  }

  render() {
    const { pageCount = 1, users, loadingUsersList, t } = this.props;
    const { sortBy, order, showDeleteUserModal, selectedUser } = this.state;
    return (
      <Container fluid className='usersContainer'>
        <div className="d-flex flex-column px-0">
          <div className="usersHeaderContainer">
            <h1>{t('Users')}</h1>
          </div>
          <div className="divider" />
          {this.renderBulkUploadSection()}
          <div className="divider" />
          <div className="searchContainer px-0">
            <SearchComponent onSearch={this.updateSearchKeyword}
              placeholder={t("Search...")}
              className={"inputField"}
            />
          </div>
          {loadingUsersList ? <Loader /> : <TableComponent
            className={'usersTable'}
            listData={users}
            manualSort
            sortedBy={sortBy}
            order={order}
            tableHeaders={['', {
              label: t('Name'),
              sortable: true,
              key: 'firstName'
            }, {
                label: t("Surname"),
                sortable: true,
                key: 'lastName'
              }, t('Email'), t('Phone'), t('admin.session_timeout'), t('Actions')]}
            onSortChange={this.handleSortChange}
            renderTableRow={this.renderTableRow}
          />}
          {pageCount > 1 && (
            <Col container alignItems="center" justify="flex-end">
              <ReactPaginate
                previousLabel={t('PREVIOUS_PAGE')}
                nextLabel={t('NEXT_PAGE')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                initialPage={0}
                disableInitialCallback={true}
              />
            </Col>
          )}
        </div>
        {showDeleteUserModal &&
          <ResetOrDeleteUserModal
            deleteUser={true}
            isOpen={showDeleteUserModal}
            user={selectedUser}
            toggle={this.toggleDeleteUserModal}
            submit={() => this.props.deleteUser(selectedUser.id)}
          />
        }
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(UnverifiedUsersComponent))
