import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import FooterComponent from '../../components/ChatComponent/Footer';
import {sendMessage} from "../../reducers/twilio";
import {updateUnsentMessage, fetchGroupMembers} from "../../reducers/chatRooms";

const mapStateToProps = state => {
  const theme = state.auth.data?.settings?.theme;

  return {
    conversationsList: state.twilio.conversationsList,
    unsentMessages: state.chatRooms.unsentMessages,
    contacts: state.contacts.list,
    licensing: state.setting.licensing,
    theme
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUnsentMessage,
  sendMessage,
  fetchGroupMembers
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterComponent);
