import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Col, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import '../../../index.scss';
import { Loader, StatusElement, UserAvatar, SelectField, InputField, } from "../../../../UIComponents";
import TableComponent from "../../../../../containers/UIComponents/TableContainer"
import SearchComponent from "../../../../UIComponents/SearchComponent";
import ResetOrDeleteUserModal from "../../Modals/ResetOrDeleteUserComponent";
import { appTheme, STATIC_BASE } from "../../../../../config";
import { ToastrService } from "../../../../../services";
import { PasswordUpdate } from './PasswordUpdate';
import { withTranslation } from 'react-i18next';
import UserListActions from '../../../../UIComponents/UserListActions';

class UsersComponent extends Component {
  fileInputRef = null;

  state = {
    showBulkUsersCreateResultsModal: false,
    showResetPasswordModal: false,
    showDeleteUserModal: false,
    isSelfRegistrationEnabled: true,
    defaultSessionTimeout: '',
    defaultSessionTimeoutError: '',
    voicePermission: 'Activate',
    videoPermission: 'Activate',
    smsPermission: 'Activate',
    delSmsPermission: 'Activate',
    speechToTextPermission: 'Activate',
    mfaPermission: 'Disabled',
    selectedUser: {},
    keyword: '',
    page: 0,
    pageSize: 10,
    sortBy: "firstName",
    order: "asc",
  };

  componentDidMount() {
    this.updateUsersList();
    this.props.getSettings();
    this.setState({ defaultSessionTimeout: this.props.sessionTimeout || '', isSelfRegistrationEnabled: !!this.props.isSelfRegistrationEnabled })
  }

  componentDidUpdate(prevProps) {
    const { updatingUser, errorOnUpdatingUser, deletingUser, errorOnDeletingUser, sessionTimeout, isSelfRegistrationEnabled, bulkUsersCreateResults, bulkCreatingUsers, bulkCreatingUsersError } = this.props,
      { updatingUser: prevUpdatingUser, deletingUser: prevDeletingUser, sessionTimeout: prevSessionTimeout, isSelfRegistrationEnabled: prevIsSelfRegistrationEnabled, bulkUsersCreateResults: prevBulkUsersCreateResults, bulkCreatingUsers: prevBulkCreatingUsers } = prevProps,
      { showResetPasswordModal, showDeleteUserModal, defaultSessionTimeout, defaultSessionTimeoutError } = this.state;

    if (showResetPasswordModal && !updatingUser && prevUpdatingUser && !errorOnUpdatingUser) {
      this.toggleResetPasswordModal();
    } else if (showDeleteUserModal && !deletingUser && prevDeletingUser && !errorOnDeletingUser) {
      this.updateUsersList();
      this.toggleDeleteUserModal();
    }

    if (typeof +defaultSessionTimeout === 'number' && !Number.isNaN(+defaultSessionTimeout) && !!defaultSessionTimeoutError) {
      this.setState({ defaultSessionTimeoutError: '' });
    }

    if (!prevSessionTimeout && sessionTimeout) {
      this.setState({ defaultSessionTimeout: sessionTimeout })
    }

    if (prevIsSelfRegistrationEnabled !== isSelfRegistrationEnabled) {
      this.setState({ isSelfRegistrationEnabled: JSON.parse(isSelfRegistrationEnabled) })
    }

    if (prevBulkCreatingUsers && !bulkCreatingUsers && !prevBulkUsersCreateResults && (!!bulkUsersCreateResults || !!bulkCreatingUsersError)) {
      this.setState({ showBulkUsersCreateResultsModal: true })
    }
  }

  handleStatusChange = (status, user) => {
    this.props.changeUserStatus(user.id, status.value.toLowerCase())
  };

  handleRoleChange = (status, user) => {
    const newStatus = status.value.replace(/\s/g, '');
    if (newStatus !== user.role.name) {
      this.props.changeUsersRole(user.id, newStatus)
    }
  }

  handleBulkUsersCreateResultsModalClose = () => {
    this.setState({ showBulkUsersCreateResultsModal: false });
    this.props.clearBulkUsersCreateResults();
  }

  onDefaultSessionTimeoutChange = e => {
    const value = Number(e.target.value)

    if (typeof value === 'number' && !Number.isNaN(value)) {
      if (value < 0) {
        return this.setState({ defaultSessionTimeoutError: 'Input value should not be less than 0.' })
      } else if (value > 100000) {
        return this.setState({ defaultSessionTimeoutError: 'Input value should not be greater than 100000.' })
      }
      this.setState({ defaultSessionTimeout: value })
    } else {
      this.setState({ defaultSessionTimeoutError: 'Input value should be a number.' })
    }
  }

  handleDefaultSessionTimeout = () => {
    const value = this.state.defaultSessionTimeout
    this.props.changeSetting({ key: 'sessionTimeout', value })
    this.updateUsersList();
  }

  updateSelfRegistrationStatus = () => {
    const value = this.state.isSelfRegistrationEnabled
    this.props.changeSetting({ key: 'isSelfRegistrationEnabled', value })
  }

  handlePermissionChange = (key, status, user) => {
    const newStatus = status.value === 'Active';
    if (newStatus !== user?.permissions?.[key]) {
      this.props.updateUserPermissions({
        id: user.id,
        [key]: newStatus,
      })
    }
  };

  handleMfaPermissionChange = (status, user) => {
    const newStatus = status.value;
    if (newStatus !== user?.permissions?.mfa) {
      this.props.updateUserPermissions({
        id: user.id,
        mfa: newStatus,
      })
    }
  };

  updateAllUsersPermissions = (name) => {
    let value = this.state[`${name}Permission`] === 'Activate';
    if (name === 'mfa') value = this.state[`${name}Permission`];
    
    this.props.updateAllUsersPermissions({
      [name]: value,
    })
  };

  toggleResetPasswordModal = (selectedUser = {}) => {
    this.setState({ showResetPasswordModal: !this.state.showResetPasswordModal, selectedUser })
  };

  toggleDeleteUserModal = (selectedUser = {}) => {
    this.setState({ showDeleteUserModal: !this.state.showDeleteUserModal, selectedUser })
  };

  handleSelectFieldChange = (value, name) => {
    this.setState({ [name]: value })
  };

  updateSearchKeyword = (keyword) => {
    this.setState({ keyword, page: 0 }, this.updateUsersList)
  };

  updateUsersList = () => {
    const { keyword, page, pageSize, sortBy, order } = this.state;

    this.props.getUsersList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize,
      sortBy,
      order
    })
  };

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page }, this.updateUsersList);
  };

  renderTableRow = user => {
    const { selectedTheme, authUser, licensing, t } = this.props;
    const statusOptions = [
      {
        class: 'success',
        value: 'Active'
      },
      {
        class: 'danger',
        value: 'Inactive'
      }
    ];

    const mfaOptions = [
      {
        class: 'danger',
        value: 'Disabled'
      },
      {
        class: 'success',
        value: 'Enabled'
      },
      {
        class: 'warning',
        value: 'Optional'
      },
    ];

    const roleOptions = [
      {
        class: 'admin',
        value: 'Admin'
      },
      {
        class: 'facilityAdmin',
        value: 'Facility Admin'
      },
      {
        class: 'regular',
        value: 'Regular'
      }
    ];

    let sessionTimeout = user.sessionTimeout || '-';
    if (user.isDefaultSessionTimeout) {
      sessionTimeout = (<span className="badge badge-secondary">{t('default')}</span>)
    }

    const phone = `${user.phoneCountryCode || ""}${user.phone}`;
    const isAdmin = user.role && user.role.name === "Admin";
    const authIsAdmin = authUser?.role?.name === "Admin"

    return <>
      <td className="avatar-cell">
        <div className="avatar">
          <div className="avatar-img-container">
            <UserAvatar user={user} className="small" />
          </div>
        </div>
      </td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email}</td>
      <td>{phone}</td>
      <td>{sessionTimeout}</td>
      <td>
        {user.superadmin ? <StatusElement
          options={[{
            class: 'admin',
            value: 'Super Admin'
          }]}
          status={{
            class: 'admin',
            value: 'Super Admin'
          }}
          onChange={(status) => this.handleRoleChange(status, user)}
          disabled
        /> : <StatusElement
          options={authIsAdmin ? roleOptions : [roleOptions[1], roleOptions[2]]}
          status={isAdmin ? roleOptions[0]
            : user.role && user.role.name === "FacilityAdmin"
              ? roleOptions[1]
              : roleOptions[2]
          }
          onChange={(status) => this.handleRoleChange(status, user)}
          disabled={isAdmin && !authIsAdmin}
        />}
      </td>
      <td>
        {!user.superadmin && <StatusElement
          options={statusOptions}
          status={user.active ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handleStatusChange(status, user)}
        />}
      </td>
      {licensing.voiceCalls && <td>
        <StatusElement
          options={statusOptions}
          status={user?.permissions?.voice ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handlePermissionChange('voice', status, user)}
        />
      </td>}
      {licensing.video && <td>
        <StatusElement
          options={statusOptions}
          status={user?.permissions?.video ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handlePermissionChange('video', status, user)}
        />
      </td>}
      {licensing.sendSMS && <td>
        <StatusElement
          options={statusOptions}
          status={user?.permissions?.sms !== false ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handlePermissionChange('sms', status, user)}
        />
      </td>}
      <td>
        <StatusElement
          options={statusOptions}
          status={user?.permissions?.delSms !== false ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handlePermissionChange('delSms', status, user)}
          disabled={!authIsAdmin}
        />
      </td>
      {licensing.voiceToText && <td>
        <StatusElement
          options={statusOptions}
          status={user?.permissions?.speechToText !== false ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handlePermissionChange('speechToText', status, user)}
          disabled={!authIsAdmin}
        />
      </td>}
      <td>
        <StatusElement
          options={mfaOptions}
          status={typeof user?.permissions?.mfa === 'string' ? mfaOptions.find((x) => x.value === user.permissions.mfa) : mfaOptions[0]}
          onChange={(status) => this.handleMfaPermissionChange(status, user)}
          disabled={!authIsAdmin}
        />
      </td>
      <td>
        <UserListActions user={user} t={t} selectedTheme={selectedTheme}
          resetUserMFA={() => this.props.resetUserMFA(user.id)}
          toggleResetPasswordModal={() => this.toggleResetPasswordModal(user)}
          toggleDeleteUserModal={() => this.toggleDeleteUserModal(user)}
        />
      </td>
    </>
  };

  renderCallStatusBulkActionSections() {
    const { licensing, t } = this.props;
    const options = [
      {
        value: 'Activate',
        label: t('admin.activate_all')
      },
      {
        value: 'Deactivate',
        label: t('admin.deactive_all')
      }
    ];
    const mfaOptions = [
      {
        value: 'Disabled',
        label: 'Disabled'
      },
      {
        value: 'Enabled',
        label: 'Enabled'
      },
      {
        value: 'Optional',
        label: 'Optional'
      },
    ];
    const selfRegistrationOptions = [
      {
        value: true,
        label: t('admin.enable_self_register')
      },
      {
        value: false,
        label: t('admin.disable_self_register')
      }
    ];
    const { voicePermission, videoPermission, isSelfRegistrationEnabled, smsPermission, delSmsPermission, speechToTextPermission, mfaPermission } = this.state;

    return (
      <>
        <div className='callStatusBulkActionContainer'>
          <p>{t('admin.def_ession_timeout')}</p>
          <InputField
            className="userSessionTimeout"
            type="text"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault()
              }
            }}
            value={this.state.defaultSessionTimeout}
            placeholder={t("please_enter")}
            onChange={this.onDefaultSessionTimeoutChange}
            error={this.state.defaultSessionTimeoutError}
          />
          <Button disabled={!+this.state.defaultSessionTimeout} className="submitButton" onClick={this.handleDefaultSessionTimeout}>{t('Submit')}</Button>
        </div>
        <div className='callStatusBulkActionContainer'>
          <p>{t('admin.self_registration')}</p>
          <SelectField options={selfRegistrationOptions}
            className='multiSelectDropdown'
            defaultValue={isSelfRegistrationEnabled}
            onChange={value => this.handleSelectFieldChange(value, 'isSelfRegistrationEnabled')}
          />
          <Button className="submitButton" onClick={this.updateSelfRegistrationStatus}>{t('Submit')}</Button>
        </div>
        {licensing.voiceCalls && <div className='callStatusBulkActionContainer'>
          <p>{t('admin.voice_call')}</p>
          <SelectField options={options}
            className='multiSelectDropdown'
            defaultValue={voicePermission}
            onChange={value => this.handleSelectFieldChange(value, 'voicePermission')}
          />
          <Button disabled={!this.state.voicePermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('voice')}>{t('Submit')}</Button>
        </div>}
        {licensing.video && <div className='callStatusBulkActionContainer'>
          <p>{t('admin.video_call')}</p>
          <SelectField options={options}
            className='multiSelectDropdown'
            defaultValue={videoPermission}
            onChange={value => this.handleSelectFieldChange(value, 'videoPermission')}
          />
          <Button disabled={!this.state.videoPermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('video')}>{t('Submit')}</Button>
        </div>}
        {licensing.sendSMS && <div className='callStatusBulkActionContainer'>
          <p>{t('admin.send_sms')}</p>
          <SelectField options={options}
            className='multiSelectDropdown'
            defaultValue={smsPermission}
            onChange={value => this.handleSelectFieldChange(value, 'smsPermission')}
          />
          <Button disabled={!this.state.smsPermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('sms')}>{t('Submit')}</Button>
        </div>}
        <div className='callStatusBulkActionContainer'>
          <p>{t('admin.msg_delete')}</p>
          <SelectField options={options}
            className='multiSelectDropdown'
            defaultValue={delSmsPermission}
            onChange={value => this.handleSelectFieldChange(value, 'delSmsPermission')}
          />
          <Button disabled={!delSmsPermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('delSms')}>{t('Submit')}</Button>
        </div>
        {licensing.voiceToText && <div className='callStatusBulkActionContainer'>
          <p>{t('admin.speech_to_text')}</p>
          <SelectField options={options}
            className='multiSelectDropdown'
            defaultValue={speechToTextPermission}
            onChange={value => this.handleSelectFieldChange(value, 'speechToTextPermission')}
          />
          <Button disabled={!speechToTextPermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('speechToText')}>{t('Submit')}</Button>
        </div>}

        <div className='callStatusBulkActionContainer'>
          <p>{t('MFA')}</p>
          <SelectField options={mfaOptions}
            className='multiSelectDropdown'
            defaultValue={mfaPermission}
            onChange={value => this.handleSelectFieldChange(value, 'mfaPermission')}
          />
          <Button disabled={!mfaPermission} className="submitButton" onClick={() => this.updateAllUsersPermissions('mfa')}>{t('Submit')}</Button>
        </div>
      </>
    )
  }

  handleSortChange = (sortBy, order) => {
    if (sortBy === this.state.sortBy && order === this.state.order) {
      return
    }
    this.setState({ sortBy, order }, this.updateUsersList)
  };

  selectFile = () => {
    this.fileInputRef.click();
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return
    }
    if (file.size > 2000000) {
      ToastrService.error('The maximum file size allowed for uploads is 2MB')
    } else {
      this.props.bulkUsersCreate(e.target.files[0])
    }
  }

  renderBulkUploadSection() {
    const { bulkCreatingUsers, selectedTheme, t } = this.props;
    return (
      <div className="d-flex flex-column align-items-end">
        <div className={`d-flex align-items-center bulkUploadContainer${selectedTheme === appTheme.DARK ? ' dark-mode' : ''}`}>
          <a href={`${STATIC_BASE}/template.csv`}>{t('admin.download_cvs')}</a>
          <input
            key={new Date()}
            type="file"
            ref={inputRef => this.fileInputRef = inputRef}
            onChange={this.handleFileChange}
            accept=".csv"
          />
          <Button disabled={bulkCreatingUsers} onClick={this.selectFile} className="submitButton" >{t('admin.bulk_upload')}</Button>
        </div>
      </div>
    )
  }

  render() {
    const { history, pageCount = 1, users, loadingUsersList, bulkUsersCreateResults, bulkCreatingUsersError, selectedTheme, licensing, t } = this.props;
    const { showResetPasswordModal, showDeleteUserModal, selectedUser, sortBy, order, showBulkUsersCreateResultsModal } = this.state;

    return (
      <Container fluid className='usersContainer'>
        <div className="d-flex flex-column px-0">
          <div className="usersHeaderContainer">
            <h1>{t('Users')}</h1>
            <Button onClick={() => history.push('users/new')} className="submitButton">{t('admin.add_user')}</Button>
          </div>
          <div className="divider" />
          {this.renderBulkUploadSection()}
          <PasswordUpdate />
          <div className="divider" />
          <div className="bulkActionsWrap">
            {this.renderCallStatusBulkActionSections()}
          </div>
          <div className="searchContainer px-0">
            <SearchComponent onSearch={this.updateSearchKeyword}
              placeholder={t("Search...")}
              className={"inputField"}
            />
          </div>
          {loadingUsersList ? <Loader /> : <TableComponent
            className={'usersTable'}
            listData={users}
            manualSort
            sortedBy={sortBy}
            order={order}
            tableHeaders={['', {
              label: t('Name'),
              sortable: true,
              key: 'firstName'
            }, {
                label: t("Surname"),
                sortable: true,
                key: 'lastName'
              }, t('Email'), t('Phone'), t('admin.session_timeout'), 
              t('Role'), t('Status'), 
              licensing.voiceCalls ? t('admin.voice_call') : undefined, 
              licensing.video ? t('admin.video_call') : undefined, 
              licensing.sendSMS ? t('admin.send_sms') : undefined, 
              t('admin.msg_delete'),
              licensing.voiceToText ? t('admin.speech_to_text') : undefined, 'MFA', t('Actions')].filter(x => x !== undefined)}
            onSortChange={this.handleSortChange}
            renderTableRow={this.renderTableRow}
          />}
          {pageCount > 1 && (
            <Col container alignItems="center" justify="flex-end">
              <ReactPaginate
                previousLabel={t('PREVIOUS_PAGE')}
                nextLabel={t('NEXT_PAGE')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                initialPage={0}
                disableInitialCallback={true}
              />
            </Col>
          )}
        </div>
        {showResetPasswordModal &&
          <ResetOrDeleteUserModal
            deleteUser={false}
            isOpen={showResetPasswordModal}
            user={selectedUser}
            toggle={this.toggleResetPasswordModal}
            submit={() => this.props.updateUser({ ...selectedUser, id: selectedUser.id, changePassword: true })}
          />
        }
        {showDeleteUserModal &&
          <ResetOrDeleteUserModal
            deleteUser={true}
            isOpen={showDeleteUserModal}
            user={selectedUser}
            toggle={this.toggleDeleteUserModal}
            submit={() => this.props.deleteUser(selectedUser.id)}
          />
        }
        {
          showBulkUsersCreateResultsModal && (
            <Modal isOpen={showBulkUsersCreateResultsModal} toggle={this.handleBulkUsersCreateResultsModalClose} className={`bulkUsersCreateResultsModal${selectedTheme === appTheme.DARK ? ' dark-mode' : ''}`} size="sm">
              <ModalHeader toggle={this.handleBulkUsersCreateResultsModalClose}>{'Bulk Users Upload Results'}</ModalHeader>
              <ModalBody className="d-flex flex-column px-4 pb-0">
                {!!bulkCreatingUsersError ? <p>{bulkCreatingUsersError}</p> : bulkUsersCreateResults?.map((result, index) => (
                  <div key={`${result?.user?.email}-${index}`}>
                    <div className="d-flex flex-column mb-2">
                      <div className="d-flex">
                        <p className="dataLabel mb-1">{t('Email')}</p>
                        <p className="dataValue mb-1">{result?.user?.email || "-"}</p>
                      </div>
                      <div className="d-flex">
                        <p className="dataLabel mb-1">{t('Status')}</p>
                        <p className="dataValue mb-1">{`${result?.status || "-"}${result?.user?.rowNumber ? `, ${result.user.rowNumber} row` : ""}`}</p>
                      </div>
                      {
                        result?.reason &&
                        <div className="d-flex">
                          <p className="dataLabel mb-1">{t('Reason')}</p>
                          <p className="dataValue mb-1">{result.reason}</p>
                        </div>
                      }
                      {
                        !!result?.errors &&
                        (
                          result.errors?.length === 1 ?
                            <div className="d-flex">
                              <p className="dataLabel mb-1">{t('Error')}</p>
                              <p className="dataValue mb-1">{result.errors[0].replace('profession', 'clinical role')}</p>
                            </div> :
                            result.errors?.map((error, index) => (
                              <div key={`${error}-${index}`} className="d-flex">
                                <p className="dataLabel mb-1">{index === 0 ? "Errors" : ""}</p>
                                <p className="dataValue mb-1">{error.replace('profession', 'clinical role')}</p>
                              </div>
                            ))
                        )
                      }
                    </div>
                    {index !== bulkUsersCreateResults.length - 1 && <div className="divider mb-2" />}
                  </div>
                ))}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-center">
                <Button color="secondary" onClick={this.handleBulkUsersCreateResultsModalClose}>{t('CLOSE')}</Button>
              </ModalFooter>
            </Modal>
          )
        }
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(UsersComponent))
