import React, { useEffect, useState } from "react";
import { LogoImage } from "../../services/app-setting.hook";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import i18n from "../../i18n";
import { contentsData } from "../../config";

export const InformationComponent = () => {
  const [informationData, setInformationData] = useState({ en: '', fr: '' });
  const lang = i18n.language === 'fr' ? 'fr' : 'en';

  useEffect(() => {
    fetch(`${contentsData.CDN_URL}/${contentsData.INFORMATION_PATH}`)
        .then(response => response.json())
        .then(data => {
            setInformationData({ en: data.en, fr: data.fr });
        });
  }, []);

  return (
  <div className="pageContainer aboutUs">
    <div className="pageHeader">
      <div className="pageHeaderLogoWrap">
        <LogoImage src='whiteLogoBig' className="aboutUsLogo" />
        <LogoImage src='aboutUsHeader' className="aboutUsTitle" />
      </div>
    </div>
    <div className="pageContent">
      <div className="leftSection" dangerouslySetInnerHTML={{__html: informationData?.[lang] }} />
      <div className="rightSection">
        <img
          src="/assets/images/RadiologyResidentConsultation.jpg"
          className="topLeft"
          alt=""
        />
        <img src="/assets/images/LadyDoctor.jpg" className="topRight" alt="" />
        <img
          src="/assets/images/MaleDoctorTexting.jpg"
          className="bottomLeft"
          alt=""
        />
        <img
          src="/assets/images/MultipleDoctorVideoChat.jpg"
          className="bottomRight"
          alt=""
        />
      </div>
    </div>
  </div>
)};
