import React from 'react'
import { Col } from "reactstrap";
import SelectField from './SelectField';
import i18n from '../../i18n';
import { useState, useEffect } from 'react';

export const KeyValueListSelector = ({ list, onChange, value, label, name }) => {
    const [items, setItems] = useState([])

    useEffect(() => {
      setItems(list)
    }, [list])

    return (
      <Col xs={12}
          className=" px-0 ieCol d-flex justify-content-center lang-select">
          <div>
              <p className="mr-3">{label}</p>
              <div className="d-flex flex-column field-column">
                  <SelectField
                      name={name}
                      className='multiSelectDropdown '
                      options={items}
                      isMulti={false}
                      value={value}
                      defaultValue={value}
                      withoutCheckboxes
                      onChange={onChange}
                  />
              </div>
          </div>
      </Col>
    )
}
