/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Button } from "reactstrap";
import { Auth as CognitoAuth } from 'aws-amplify';
import QRCode from "react-qr-code";
import { InputField, Loader } from '../UIComponents';
import history from '../../helpers/history';
import { routes } from '../../config';
import { isMobile as isMobileFxn } from '../../helpers/function';

import './MFAComponent.scss';


export const MFAComponent = (props) => {
    const [mfaSecret, setMfaSecret] = useState()
    const [loading, setLoading] = useState(false);
    const [cognitoUser, setCognitoUser] = useState();
    const [mfaPreference, setMfaPreference] = useState()
    const [error, setError] = useState('');

    const isMobile = useMemo(() => { return isMobileFxn() }, [])

    useEffect(() => {
        if (props.tempLoginData?.cognitoUser) {
            checkCognitoUser(props.tempLoginData?.cognitoUser)
        } else {
            history.push(routes.login.path)
        }
    }, [])

    useEffect(() => {
        if (props.token) {
            history.push(routes.recent.path)
        }
    }, [props.token])

    const resumeLogin = () => {
        props.setUserSession(props.tempLoginData);
    }

    const checkCognitoUser = async (cognitoUser) => {
        try {
            setCognitoUser(cognitoUser);
            if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setMfaPreference('SOFTWARE_TOKEN_MFA');
                setLoading(false);
            } else {
                setLoading(false);
                setMfaPreference('NOMFA');
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const setupMFA = async () => {
        setLoading(true);
        const secretCode = await CognitoAuth.setupTOTP(cognitoUser);

        setMfaSecret(secretCode);
        setLoading(false);
    }

    const verifyTotpSetup = async (code) => {
        setLoading(true);
        try {
            await CognitoAuth.verifyTotpToken(cognitoUser, code);

            await CognitoAuth.setPreferredMFA(cognitoUser, 'TOTP');

            props.setMfaData(props.tempLoginData, { setup: true });
        } catch (error) {
            setError('Incorrect MFA code, try again')
            setLoading(false);
        }
    }

    const confirmMfaCode = async (code) => {
        setError('')
        setLoading(true);
        try {
            const user = props.tempLoginData.cognitoUser;
            await CognitoAuth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');

            // resumeLogin()
            props.setMfaData(props.tempLoginData, { setup: true });
        } catch (error) {
            setError('Incorrect MFA code, try again')
            setLoading(false);
        }
    }

    const handleIntroDismiss = (remind = false) => {
        if (remind) {
            resumeLogin()
        } else {
            props.setMfaData(props.tempLoginData, { remind: false });
        }
    }

    if (loading) {
        return (
            <Container fluid className="pageContainer loginLoading bg-light">
                <Loader />
            </Container>
        )
    }

    if (mfaSecret) return <Container fluid className="MFAComponent mfaSetup pageContainer pt-64 bg-light">
        <SetUpDevice
            mfaSecret={mfaSecret} onSubmit={verifyTotpSetup}
            error={error} isMobile={isMobile}
            email={props.tempLoginData.user?.email}
        />
    </Container>


    return <Container fluid className="MFAComponent authPageContainer pageContainer align-items-center pt-64 bg-light">
        {(mfaPreference === "NOMFA" && !mfaSecret) && <Intro
            onContinue={setupMFA} onDismiss={handleIntroDismiss}
            mfaPermission={props.tempLoginData.user?.permissions?.mfa}
        />}
        {mfaPreference === "SOFTWARE_TOKEN_MFA" && <ConfirmMFA
            onSubmit={confirmMfaCode} error={error}
        />}
    </Container>

}

const Intro = ({ onDismiss, onContinue, mfaPermission }) => {
    return <div className='Intro'>
        <p>Add multi-factor authentication (MFA) to improve security for this account.</p>

        <div className='buttons'>
            {mfaPermission === 'Optional' && <button className='later' onClick={() => onDismiss(true)}>Remind me later</button>}
            <button className='go' onClick={onContinue}>Continue</button>
        </div>

        {mfaPermission === 'Optional' && <button className='dismiss' onClick={() => onDismiss(false)}>Don't show this next time?</button>}
    </div>
}

const ConfirmMFA = ({ onSubmit, error }) => {
    const [code, setcode] = useState('')
    const handleSubmit = () => {
        if (!code) return;
        onSubmit(code)
    }

    return <div className='ConfirmMFA'>
        <h2>Multi-factor authentication (MFA)</h2>
        <p>Enter a code from your auth app below</p>
        <br />
        <InputField
            type='number' placeholder='Enter code from app' value={code}
            className="codeInput"
            onChange={({ target }) => setcode(target.value)} error={error}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && e.target.value.length === 6) {
                    handleSubmit()
                }
            }}
            autoFocus
        />
        <br />
        <Button className="submitButton w-100" onClick={handleSubmit}>Submit</Button>
    </div>
}

const SetUpDevice = ({ mfaSecret, onSubmit, error, email, isMobile }) => {
    const [code, setcode] = useState('')

    const handleSubmit = () => {
        if (!code) return;
        onSubmit(code)
    }

    return <div className='step-container'>
        <h4 className='step-title'>Setup device</h4>
        <div className='step-item'>
            <div className='number'>1</div>
            <p>Install a compatible application such as Google Authenticator, Duo Mobile, or Authy app on your mobile device or computer.</p>
        </div>
        <div className='step-item'>
            <div className='number'>2</div>

            <div>
                <p>Open your authenticator app, then use the app to scan the code</p>
                <div style={{ height: 200, minWidth: 200, marginRight: 10 }}>
                    {mfaSecret && <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                        value={`otpauth://totp/AWSCognito:${email}?secret=${mfaSecret}&issuer=ECS`}
                        viewBox={`0 0 256 256`}
                    />}
                </div>
                <br />
                <ShowCode code={mfaSecret} />
            </div>
        </div>
        <div className='step-item'>
            <div className='number'>3</div>
            <div>
                <p>Enter a code from your auth app below</p>
                <InputField
                    type='number' placeholder='Enter code' value={code}
                    className="codeInput"
                    autoFocus={!isMobile}
                    onChange={({ target }) => setcode(target.value)} error={error}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && e.target.value.length === 6) {
                            handleSubmit()
                        }
                    }}
                ></InputField>
                <br />
                <Button className="submitButton w-100" onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    </div>
}

const ShowCode = ({ code = '' }) => {
    const [show, setShow] = useState(false);
    return <div>
        <div>Alternatively, you can type a secret key.</div>
        <p>{show ? code : <span className='show' onClick={() => setShow(true)}>Show secret key</span>}</p>
    </div>
}