import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as PublicRoute, Router, Switch } from 'react-router-dom';
import IdleTimer from "react-idle-timer";
import history from '../helpers/history';
import { routes, appTheme, userStatuses } from '../config';

import SideBarComponent from '../containers/SideBarContainer';
import RegisterComponent from '../containers/Auth/RegisterContainer';
import LoginComponent from '../containers/Auth/LoginContainer';
import GroupModal from '../containers/GroupModal/GroupModalContainer';
import ForgotPasswordComponent from '../containers/Auth/ForgotPasswordContainer';
import ResetPasswordComponent from '../containers/Auth/ResetPasswordContainer';
import EmailOrMobileConfirmComponent from '../containers/Auth/EmailOrMobileConfirmContainer';
import SettingsComponent from '../containers/Settings/SettingsContainer';
import UserComponent from '../containers/Admin/User/UserContainer';
import ContactsComponent from '../containers/Contacts/ContactsContainer';
import RecentComponent from '../containers/Recents/RecentsContainer';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import Route from '../containers/Auth/Route';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import AdminComponent from "../containers/Admin/AdminContainer";
import EditFacilityComponent from "../containers/Admin/Facility/EditFacilityContainer";
import EditDepartmentComponent from "../containers/Admin/Department/EditDepartmentContainer";
import EditClinicalRoleComponent from "../containers/Admin/ClinicalRoles/EditClinicalRoleContainer";
import EditSpecialtyComponent from "../containers/Admin/Specialty/EditSpecialtyContainer";
import EditSuffixComponent from "../containers/Admin/Suffix/EditSuffixContainer";
import CallComponent from "../containers/Call/CallContainer";
import { bindActionCreators } from "redux";
import { logOut, setIdleTimer, updateUserToken } from "../reducers/auth";
import ToastrService from "../services/toastrService";
import { changeChatStatusAction } from "../reducers/user";
import { StorageService } from "../services";
import { getAcceptedCall } from "../helpers/calls";
import { AboutUsComponent } from "./AboutUsComponents/AboutUsComponent";
import { InformationComponent } from "./AboutUsComponents/InformationComponent";
import PasswordUpdateModal from '../containers/PasswordUpdateModal/PasswordUpdateModal';
import { withTranslation } from 'react-i18next';
import EULA from '../containers/EULA/EULA';
import MFAContainer from '../containers/Auth/MFAContainer';
import { fetchAppSettings } from '../reducers/setting';

export let setAwayTimer = null
export let updateTokenInterval = null

class App extends Component {
  idleTimer = null
  awayTimerValue = 5 * 60 * 1000

  componentDidMount() {
    const { theme } = this.props;
    if (theme === appTheme.DARK) {
      this.handleThemeChange()
    }

    if (!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      let link = document.querySelector("link[rel~='icon']");
      link.href = 'favicon_.ico';
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      let link = document.querySelector("link[rel~='icon']");
      if (event.matches) {
        link.href = 'favicon.ico';
      } else {
        link.href = 'favicon_.ico';
      }
    });

    setTimeout(() => {
      if (!this.props.licensing.chat) {
        this.props.fetchAppSettings()
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { sessionTimeout, updateUserToken, userId, chatStatus } = this.props;

    if (prevProps.theme !== this.props.theme || prevProps.userId !== userId) {
      this.handleThemeChange()
    }

    if (!prevProps.userId && userId && !setAwayTimer) {
      this.handleOnAction(true)
    }

    if (!prevProps.chatStatus !== chatStatus) {
      this.handleOnAction(false)
    }

    if (!prevProps.sessionTimeout && sessionTimeout && !updateTokenInterval) {
      const intervalTimeout = Math.round(sessionTimeout * 60 / 3)
      updateTokenInterval = setInterval(updateUserToken, intervalTimeout * 1000)
      this.idleTimer.reset()
    }
  }

  handleStatusChange = value => {
    const { userId, changeChatStatus } = this.props
    changeChatStatus(userId, value)
  }

  handleThemeChange() {
    const body = document.querySelector('body');
    if (this.props.theme === appTheme.DARK) {
      body.classList.add('dark')
    } else {
      body.classList.remove('dark')
    }
  }

  handleOnActive = () => {
    const { userId, sessionTimeout } = this.props;
    const storageService = new StorageService();
    const userSessions = storageService.checkUserSessions() || {}
    if (sessionTimeout && sessionTimeout !== 0) {
      const firstInactiveIndex = userSessions[userId]?.findIndex(i => i === 'inactive')
      const updatedSessions = userSessions[userId]?.map((i, index) => {
        if (index === firstInactiveIndex) return 'active'
        return i
      })
      storageService.updateUserSessions({
        ...userSessions,
        [userId]: updatedSessions
      })
      setAwayTimer = null
      updateTokenInterval = null
    }
  }

  handleOnIdle = () => {
    const storageService = new StorageService();
    const userSessions = storageService.checkUserSessions() || {}
    const { sessionTimeout, calls, userId } = this.props;
    const acceptedCall = getAcceptedCall(calls, userId);
    if (sessionTimeout && sessionTimeout !== 0 && !acceptedCall) {
      const firstActiveIndex = userSessions[userId]?.findIndex(i => i === 'active')
      const updatedSessions = userSessions[userId].map((i, index) => {
        if (index === firstActiveIndex) return 'inactive'
        return i
      })
      storageService.updateUserSessions({
        ...userSessions,
        [userId]: updatedSessions
      })
      if (!updatedSessions.includes('active')) {
        storageService.updateUserSessions({
          ...userSessions,
          [userId]: []
        })
        clearInterval(updateTokenInterval)
        clearTimeout(setAwayTimer)
        setAwayTimer = null
        updateTokenInterval = null
        this.props.logOut()
        ToastrService.info('Your session has expired. Please log back in to continue.')
      }
    }
  }

  handleOnAction = (setAvailable = true) => {
    const { chatStatus, isStatusSettedManually } = this.props

    clearTimeout(setAwayTimer)
    setAwayTimer = null

    if (chatStatus === userStatuses[0].value) {
      setAwayTimer = setTimeout(() => this.handleStatusChange(userStatuses[1].value), this.awayTimerValue)
    } else if (chatStatus === userStatuses[1].value && !isStatusSettedManually && setAvailable) {
      this.handleStatusChange(userStatuses[0].value)
    }
  }

  render() {
    const { sessionTimeout, isAppDisabled, token, t } = this.props;
    return (
      <div className={`appContainer${token ? '' : ' bg-light'}`}>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          timeout={(sessionTimeout * 60 * 1000) || 0}
          onIdle={this.handleOnIdle}
          onAction={() => this.handleOnAction(true)}
          onActive={this.handleOnActive}
        >
          {isAppDisabled && <div className='lostConnectionMessage'>
            <div className='lostConnectionMessageContent'>
              <h3>{t('Connection_Lost')}</h3>
              <p>{t('network_problem')}</p>
            </div>
          </div>}
          <DndProvider backend={Backend}>
            <Router history={history}>
              <SideBarComponent />
              <Switch>
                <Route exact path={routes.recent.path} component={RecentComponent} />
                <PublicRoute exact path={routes.register.path} component={RegisterComponent} />
                <PublicRoute exact path={routes.forgotPassword.path} component={ForgotPasswordComponent} />
                <PublicRoute exact path={routes.resetPassword.path} component={ResetPasswordComponent} />
                <PublicRoute exact path={routes.forceChangePassword.path} component={ResetPasswordComponent} />
                <PublicRoute exact path={routes.emailConfirm.path} component={EmailOrMobileConfirmComponent} />
                <PublicRoute exact path={routes.mobileConfirm.path} component={EmailOrMobileConfirmComponent} />
                <PublicRoute exact path={routes.aboutUs.path} component={AboutUsComponent} />
                <PublicRoute exact path={routes.eula.path} component={EULA} />
                <PublicRoute exact path={routes.information.path} component={InformationComponent} />
                <Route exact path={routes.settings.path} component={SettingsComponent} />
                <Route exact path={routes.facility.path} component={EditFacilityComponent} />
                <Route exact path={routes.department.path} component={EditDepartmentComponent} />
                <Route exact path={routes.role.path} component={EditClinicalRoleComponent} />
                <Route exact path={routes.specialty.path} component={EditSpecialtyComponent} />
                <Route exact path={routes.suffix.path} component={EditSuffixComponent} />
                <Route exact path={routes.user.path} component={UserComponent} />
                <Route exact path={routes.contacts.path} component={ContactsComponent} />
                <PublicRoute path={routes.error.path} component={ErrorPage} />
                <Route path={routes.admin.path} component={AdminComponent} />
                <PublicRoute path={routes.login.path} component={LoginComponent} />
                <PublicRoute path={routes.mfaPage.path} component={MFAContainer} />
                <PublicRoute path='*' component={ErrorPage} />
              </Switch>
            </Router>
            <CallComponent />
            <GroupModal />
            <PasswordUpdateModal />
          </DndProvider>
        </IdleTimer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const userSettings = state.auth.data?.settings || {};

  const theme = userSettings.theme;

  return {
    theme,
    sessionTimeout: state.auth.data?.sessionTimeout > 100000 ? 100000 : state.auth.data?.sessionTimeout,
    isAppDisabled: state.auth.isAppDisabled,
    calls: state.calls.calls,
    userId: state.auth.data.id,
    chatStatus: state.auth.data.chatStatus,
    isStatusSettedManually: state.users.isStatusSettedManually,
    token: state.auth.token,
    licensing: state.setting.licensing,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut,
  updateUserToken,
  setIdleTimer,
  fetchAppSettings,
  changeChatStatus: changeChatStatusAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(App));
